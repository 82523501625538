/* eslint-disable camelcase */
import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'
import { formatUserName } from '~/utils/user'

export interface Checklist {
  id: string
  title: string
  description: string | null
  lastUpdaterName: string | undefined
  createdAt: string
  updatedAt: string
}

export default function checklistsStore() {
  const { $api } = useContext()

  const state = reactive({
    checklists: [] as Checklist[],
    totalCount: 0,
  })

  const fetchChecklists = async (
    page: number = 1,
    itemsPerPage: number = 10
  ) => {
    const response = await $api.getChecklists(page, itemsPerPage, [
      'last_updater',
    ])
    const checklists: Checklist[] = response.data.map((c) => {
      const user = response.included?.find(
        (i) => i.id === c.relationships.last_updater.data?.id
      )
      const { title, description, created_at, updated_at } = c.attributes
      return {
        id: c.id,
        title,
        description,
        lastUpdaterName: formatUserName(user),
        createdAt: formatDateTimeString(created_at),
        updatedAt: formatDateTimeString(updated_at),
      }
    })
    state.checklists = checklists
    state.totalCount = response.meta.record_count
  }

  return {
    ...toRefs(state),
    fetchChecklists,
  }
}

export type ChecklistsStore = ReturnType<typeof checklistsStore>
export const ChecklistsKey: InjectionKey<ChecklistsStore> = Symbol(
  'ChecklistsStore'
)
