














import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const { $config } = useContext()
    const isDev = !!$config.environment && $config.environment !== 'production'
    const dialog = ref(isDev)
    return {
      dialog,
    }
  },
})
