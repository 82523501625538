/* eslint-disable camelcase */
import { NuxtHTTPInstance } from '@nuxt/http'
import { Plugin } from '@nuxt/types'
// eslint-disable-next-line import/named
import { Options } from 'ky'
import { firebaseapp } from '~/plugins/firebaseapp'

const plugin: Plugin = (context, inject) => {
  const baseUrl = context.$config.http.baseURL
  inject('api', new API(context.$http, baseUrl))
}

declare module '@nuxt/types' {
  interface Context {
    $api: API
  }
}
declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  interface Store<S> {
    $api: API
  }
}

export class API {
  constructor($http: NuxtHTTPInstance, prefixUrl: string) {
    this._$http = $http.create({ prefixUrl })
    this._$http.onRequest((req) => {
      req.headers.set('Content-Type', 'application/vnd.api+json')
    })
  }

  private _$http: NuxtHTTPInstance

  get $http() {
    return this._$http
  }

  /* settings */
  setToken(token: string) {
    this._$http.setToken(token, 'Bearer')
  }

  clearToken() {
    this._$http.setToken(false)
  }

  /* user */
  async registerUser(displayName?: string): Promise<UserData.Response> {
    return await this.post('/auth/registrations', {
      display_name: displayName,
    })
  }

  async getMe(): Promise<UserData.Response> {
    return await this.get('/me')
  }

  async getUsers(): Promise<UsersData.Response> {
    return await this.get('/users')
  }

  async updateUser(user: { name: string | null }): Promise<UserData.Response> {
    const payload = {
      data: {
        type: 'users',
        attributes: {
          name: user.name,
        },
      },
    }
    return await this.patch('/me', payload)
  }

  /* checklists */
  async getChecklists<S extends keyof ChecklistData.Relationships>(
    pageNumber: number,
    pageSize: number,
    includes: S[] = []
  ): Promise<ChecklistsData.Response<S>> {
    return await this.get('/checklists', {
      searchParams: {
        'page[number]': pageNumber,
        'page[size]': pageSize,
        include: includes.join(','),
      },
    })
  }

  async getChecklist(id: string): Promise<ChecklistData.Response> {
    return await this.get(`/checklists/${id}`)
  }

  async createChecklist(title: string): Promise<ChecklistData.Response> {
    const payload = {
      data: {
        type: 'checklists',
        attributes: {
          title,
        },
      },
    }
    return await this.post('/checklists', payload)
  }

  async updateChecklist(
    id: string,
    attributes: { title?: string; description?: string }
  ): Promise<ChecklistData.Response> {
    const payload = {
      data: {
        type: 'checklists',
        id,
        attributes,
      },
    }
    return await this.patch(`/checklists/${id}`, payload)
  }

  async deleteChecklist(id: string): Promise<void> {
    return await this.delete(`/checklists/${id}`)
  }

  async downloadChecklistFile(checklistId: string) {
    return await this.getRaw(`/checklists/${checklistId}/downloads`)
  }

  async importChecklistsFromCSV(
    title: string,
    body: string
  ): Promise<ChecklistData.Response> {
    const payload = {
      title,
      body,
    }
    return await this.post('/checklists/import', payload)
  }

  /* rules */
  async getChecklistRules(
    checklistId: string,
    categoryId?: string
  ): Promise<RulesData.Response<'asset_category' | 'label'>> {
    const params: { [key: string]: string } = {
      include: 'asset_category,label',
      sort: 'position',
    }
    if (categoryId) {
      params['filter[asset_category]'] = categoryId
    }
    return await this.get(`/checklists/${checklistId}/rules`, {
      searchParams: params,
    })
  }

  async createRule(
    checklistId: string,
    attributes: {
      title: string
      advice?: string
      criteria?: string
    },
    assetCategoryId: string
  ): Promise<RuleData.Rule> {
    const payload = {
      data: {
        type: 'rules',
        attributes,
        relationships: {
          checklist: {
            data: {
              type: 'checklists',
              id: checklistId,
            },
          },
          asset_category: {
            data: {
              type: 'asset_categories',
              id: assetCategoryId,
            },
          },
        },
      },
    }

    return await this.post(`/rules`, payload)
  }

  async updateRule(
    id: string,
    attributes: {
      title?: string
      position?: number
      advice?: string
      criteria?: string
    }
  ): Promise<RuleData.Response> {
    const payload = {
      data: {
        id,
        type: 'rules',
        attributes,
      },
    }
    return await this.patch(`/rules/${id}`, payload)
  }

  async updateRuleCategory(
    id: string,
    assetCategoryId: string
  ): Promise<RuleData.Response> {
    const payload = {
      data: {
        id,
        type: 'rules',
        relationships: {
          asset_category: {
            data: {
              type: 'asset_categories',
              id: assetCategoryId,
            },
          },
        },
      },
    }

    return await this.patch(`/rules/${id}`, payload)
  }

  async updateRuleLabel(
    id: string,
    ruleId: string | null
  ): Promise<RuleData.Response<'label'>> {
    const payload = {
      data: {
        id,
        type: 'rules',
        relationships: {
          label: {
            data: {
              type: 'labels',
              id: ruleId,
            },
          },
        },
      },
    }

    return await this.patch(`/rules/${id}`, payload, {
      searchParams: {
        include: 'label',
      },
    })
  }

  async deleteRule(ruleId: string) {
    return await this.delete(`/rules/${ruleId}`)
  }

  /* asset_categories */
  async getAssetCategories(): Promise<AssetCategoriesData.Response> {
    return await this.get(`/asset-categories`)
  }

  async createAssetCategory(
    categoryName: string
  ): Promise<AssetCategoryData.Response> {
    const payload = {
      data: {
        type: 'asset_categories',
        attributes: {
          name: categoryName,
        },
      },
    }
    return await this.post('/asset-categories', payload)
  }

  async updateAssetCategory(
    id: string,
    attributes: {
      name: string
      position: number
    }
  ): Promise<AssetCategoryData.Response> {
    const payload = {
      data: {
        type: 'asset_categories',
        id,
        attributes,
      },
    }
    return await this.patch(`/asset-categories/${id}`, payload)
  }

  async deleteAssetCategory(id: string): Promise<void> {
    return await this.delete(`/asset-categories/${id}`)
  }

  /* labels */
  async getLabels(): Promise<LabelsData.Response> {
    return await this.get(`/labels`)
  }

  async createLabel(title: string, color: string): Promise<LabelData.Response> {
    const payload = {
      data: {
        type: 'labels',
        attributes: {
          title,
          color,
        },
      },
    }
    return await this.post(`/labels`, payload)
  }

  async updateLabel(
    id: string,
    attributes: {
      title: string
      color: string
      position?: number
    }
  ): Promise<LabelData.Response> {
    const payload = {
      data: {
        type: 'labels',
        id,
        attributes,
      },
    }
    return await this.patch(`/labels/${id}`, payload)
  }

  async deleteLabel(id: string): Promise<void> {
    return await this.delete(`/labels/${id}`)
  }

  /* organizations */
  async getOrganizations(
    pageNumber: number,
    pageSize: number
  ): Promise<OrganizationsData.Response> {
    return await this.get('/organizations', {
      searchParams: {
        'page[number]': pageNumber,
        'page[size]': pageSize,
      },
    })
  }

  async getOrganization(id: string): Promise<OrganizationData.Response> {
    return await this.get(`/organizations/${id}`)
  }

  async createOrganization(name: string): Promise<OrganizationData.Response> {
    const payload = {
      data: {
        type: 'organizations',
        attributes: {
          name,
        },
      },
    }
    return await this.post('/organizations', payload)
  }

  async updateOrganization(
    id: string,
    attributes: { name: string }
  ): Promise<OrganizationData.Response> {
    const payload = {
      data: {
        type: 'organizations',
        id,
        attributes,
      },
    }
    return await this.patch(`/organizations/${id}`, payload)
  }

  async deleteOrganization(id: string): Promise<void> {
    return await this.delete(`/organizations/${id}`)
  }

  /* assets */
  async getAssets(
    organizationId: string
  ): Promise<AssetsData.Response<'asset_category'>> {
    return await this.get(`/organizations/${organizationId}/assets`, {
      searchParams: {
        sort: 'position',
        include: 'asset_category',
      },
    })
  }

  async createAsset(
    organizationId: string,
    attributes: { name: string; reference_url: string },
    assetCategoryId: string
  ): Promise<AssetData.Response> {
    const payload = {
      data: {
        type: 'assets',
        attributes,
        relationships: {
          organization: {
            data: {
              type: 'organizations',
              id: organizationId,
            },
          },
          asset_category: {
            data: {
              type: 'asset_categories',
              id: assetCategoryId,
            },
          },
        },
      },
    }
    return await this.post(`/assets`, payload)
  }

  async updateAsset(
    assetId: string,
    attributes: {
      name?: string
      position?: number
      reference_url?: string
    },
    assetCategoryId?: string
  ): Promise<AssetData.Response> {
    const payload = {
      data: {
        id: assetId,
        type: 'assets',
        attributes,
      },
    }

    if (assetCategoryId) {
      payload.data = {
        ...payload.data,
        ...{
          relationships: {
            asset_category: {
              data: {
                type: 'asset_categories',
                id: assetCategoryId,
              },
            },
          },
        },
      }
    }

    return await this.patch(`/assets/${assetId}`, payload)
  }

  async deleteAsset(assetId: string): Promise<AssetData.Asset> {
    return await this.delete(`/assets/${assetId}`)
  }

  /* checksheets */
  async getChecksheets<S extends keyof ChecksheetData.Relationships>(params: {
    size: number
    includes: S[]
  }): Promise<ChecksheetsData.Response<S>> {
    return await this.get(`/checksheets`, {
      searchParams: {
        size: params.size,
        include: params.includes.join(','),
        sort: '-updated_at',
      },
    })
  }

  async getChecksheetsFromOrganization(
    organizationId: string,
    pageNumber: number,
    pageSize: number
  ): Promise<
    ChecksheetsData.Response<'first_creator' | 'last_updater' | 'checklist'>
  > {
    return await this.get(`/organizations/${organizationId}/checksheets`, {
      searchParams: {
        'page[number]': pageNumber,
        'page[size]': pageSize,
        include: 'first_creator,last_updater,checklist',
      },
    })
  }

  async getChecksheetsFromChecklist(
    organizationId: string,
    checklistId: string
  ): Promise<ChecksheetsData.Response> {
    return await this.get(`/organizations/${organizationId}/checksheets`, {
      searchParams: {
        'filter[checklist_id]': checklistId,
        sort: '-updated_at',
      },
    })
  }

  async getChecksheet<S extends keyof ChecksheetData.Relationships>(
    checksheetId: string,
    includes: S[] = []
  ): Promise<ChecksheetData.Response<S>> {
    return await this.get(`/checksheets/${checksheetId}`, {
      searchParams: {
        include: includes.join(','),
      },
    })
  }

  async downloadChecksheetFile(checksheetId: string) {
    return await this.getRaw(`/checksheets/${checksheetId}/downloads`)
  }

  async updateChecksheet(
    checksheetId: string,
    attributes: {
      name?: string
      description?: string
      advice?: string
    }
  ): Promise<ChecksheetData.Response> {
    const payload = {
      data: {
        id: checksheetId,
        type: 'checksheets',
        attributes,
      },
    }
    return await this.patch(`/checksheets/${checksheetId}`, payload)
  }

  async deleteChecksheet(
    checksheetId: string
  ): Promise<ChecksheetData.Response> {
    return await this.delete(`/checksheets/${checksheetId}`)
  }

  async transcribeChecksheet(
    name: string,
    checklistId: string,
    organizationId: string,
    prevChecksheetId?: string
  ): Promise<ChecksheetData.Response> {
    const payload: Record<string, string> = {
      name,
      checklist_id: checklistId,
      organization_id: organizationId,
    }
    if (prevChecksheetId) {
      payload.prev_checksheet_id = prevChecksheetId
    }
    return await this.post('/checksheets/transcribe', payload)
  }

  /* checksheet-assets */
  async getChecksheetAssets<S extends keyof ChecksheetAssetData.Relationships>(
    checksheetId: string,
    includes: S[] = []
  ): Promise<ChecksheetAssetsData.Response<S>> {
    return await this.get(`/checksheets/${checksheetId}/checksheet-assets`, {
      searchParams: {
        sort: 'position',
        include: includes.join(','),
      },
    })
  }

  async getChecksheetAssetsWithRules(
    checksheetId: string
  ): Promise<
    ChecksheetAssetsData.Response<
      'checksheet_rules' | 'asset',
      RuleData.Rule<'label'> | LabelData.Label
    >
  > {
    return await this.get(`/checksheets/${checksheetId}/checksheet-assets`, {
      searchParams: {
        include:
          'asset,checksheet_rules,checksheet_rules.rule,checksheet_rules.rule.label',
        sort: 'position',
      },
    })
  }

  /* checksheet-rules */
  async getChecksheetRules(
    checksheetId: string
  ): Promise<ChecksheetRulesData.Response<'rule'>> {
    return await this.get(`/checksheets/${checksheetId}/checksheet-rules`, {
      searchParams: {
        include: 'rule',
        sort: 'position',
      },
    })
  }

  async updateChecksheetRule(
    checksheetRuleId: string,
    attributes: {
      check_status?: ChecksheetRuleData.CheckStatus
      memo?: string
    }
  ): Promise<ChecksheetRuleData.Response> {
    const payload = {
      data: {
        type: 'checksheet_rules',
        id: checksheetRuleId,
        attributes,
      },
    }
    return await this.patch(`/checksheet-rules/${checksheetRuleId}`, payload)
  }

  async deleteChecksheetRule(
    checksheetRuleId: string
  ): Promise<ChecksheetRuleData.Response> {
    return await this.delete(`/checksheet-rules/${checksheetRuleId}`)
  }

  /* monitoring-keywords */
  async getMonitoringKeywords(
    organizationId: string
  ): Promise<MonitoringKeywordsData.Response> {
    return await this.get(
      `/organizations/${organizationId}/monitoring-keywords`,
      {
        searchParams: {
          sort: 'position',
        },
      }
    )
  }

  async createMonitoringKeyword(
    organizationId: string,
    attributes: {
      service: MonitoringsheetData.Services
      query: string
      display_name: string | null
      option: any
    }
  ): Promise<MonitoringKeywordData.Response> {
    const payload = {
      data: {
        type: 'monitoring_keywords',
        attributes,
        relationships: {
          organization: {
            data: {
              type: 'organizations',
              id: organizationId,
            },
          },
        },
      },
    }
    return await this.post(`/monitoring-keywords`, payload)
  }

  async updateMonitoringKeyword(
    monitoringKeywordId: string,
    attributes: {
      display_name?: string
      query?: string
      position?: number
      option?: any
    }
  ): Promise<MonitoringKeywordData.Response> {
    const payload = {
      data: {
        type: 'monitoring_keywords',
        id: monitoringKeywordId,
        attributes,
      },
    }
    return await this.patch(
      `/monitoring-keywords/${monitoringKeywordId}`,
      payload
    )
  }

  async deleteMonitoringKeyword(
    monitoringKeywordId: string
  ): Promise<MonitoringKeywordData.Response> {
    return await this.delete(`/monitoring-keywords/${monitoringKeywordId}`)
  }

  /* monitoringsheets */
  async getMonitoringsheets<
    I extends keyof MonitoringsheetData.Relationships
  >(params: {
    size: number
    includes: I[]
  }): Promise<MonitoringsheetsData.Response<I>> {
    return await this.get(`/monitoringsheets`, {
      searchParams: {
        size: params.size,
        sort: '-updated_at',
        include: params.includes.join(','),
      },
    })
  }

  async getMonitoringsheetsFromOrganization(
    organizationId: string,
    pageNumber: number,
    pageSize: number
  ): Promise<MonitoringsheetsData.Response<'first_creator' | 'last_updater'>> {
    return await this.get(`/organizations/${organizationId}/monitoringsheets`, {
      searchParams: {
        'page[number]': pageNumber,
        'page[size]': pageSize,
        sort: '-updated_at',
        include: 'first_creator,last_updater',
      },
    })
  }

  async getMonitoringsheet<
    I extends keyof MonitoringsheetData.Relationships,
    Ex extends JSONApi.Included
  >(
    id: string,
    includes: string[]
  ): Promise<MonitoringsheetData.Response<I, Ex>> {
    return await this.get(`/monitoringsheets/${id}`, {
      searchParams: {
        include: includes.join(','),
      },
    })
  }

  async updateMonitoringsheet(
    id: string,
    attributes: { name?: string; description?: string }
  ): Promise<MonitoringsheetData.Response> {
    const payload = {
      data: {
        type: 'monitoringsheets',
        id,
        attributes,
      },
    }
    return await this.patch(`/monitoringsheets/${id}`, payload)
  }

  async deleteMonitoringsheet(
    id: string
  ): Promise<MonitoringsheetData.Response> {
    return await this.delete(`/monitoringsheets/${id}`)
  }

  async transcribeMonitoringsheet(
    name: string,
    organizationId: string,
    fromDate: string,
    toDate: string
  ): Promise<void> {
    const payload: Record<string, string> = {
      name,
      organization_id: organizationId,
      from_date: fromDate,
      to_date: toDate,
    }
    return await this.post(`/monitoringsheets/transcribe`, payload)
  }

  async retranscribeMonitoringsheet(monitoringsheetId: string): Promise<void> {
    return await this.post(
      `/monitoringsheets/${monitoringsheetId}/retranscribe`
    )
  }

  /* monitoringsheet-monitoring-keywords */
  async getMonitoringsheetMonitoringKeywords(
    monitoringsheetId: string
  ): Promise<MonitoringsheetMonitoringKeywordsData.Response> {
    return await this.get(
      `/monitoringsheets/${monitoringsheetId}/monitoringsheet-monitoring-keywords`
    )
  }

  /* reputation-sentences */
  async getReputationSentences<
    S extends keyof ReputationSentenceData.Relationships
  >(
    monitoringsheetId: string,
    includes: S[] = []
  ): Promise<ReputationSentencesData.Response<S>> {
    return await this.get(
      `/monitoringsheets/${monitoringsheetId}/reputation-sentences`,
      {
        searchParams: {
          include: includes.join(','),
        },
      }
    )
  }

  async updateReputationSentence(
    id: string,
    attributes: {
      report_included: ReputationSentenceData.ReportIncluded
    }
  ): Promise<ReputationSentenceData.Response> {
    const payload = {
      data: {
        type: 'reputation_sentences',
        id,
        attributes,
      },
    }
    return await this.patch(`/reputation-sentences/${id}`, payload)
  }

  /* private methods */
  private async get<T>(
    path: string,
    options: Omit<Options, 'body'> = {}
  ): Promise<T> {
    await this.setTokenOnRequest()
    return await this._$http.$get<T>(path, options)
  }

  private async getRaw(path: string, options: Omit<Options, 'body'> = {}) {
    await this.setTokenOnRequest()
    return await this._$http.get(path, options)
  }

  private async post<T>(
    path: string,
    payload: any = {},
    options: Options = {}
  ): Promise<T> {
    await this.setTokenOnRequest()
    return await this._$http.$post<T>(path, payload, options)
  }

  private async patch<T>(
    path: string,
    payload: any,
    options: Options = {}
  ): Promise<T> {
    await this.setTokenOnRequest()
    return await this._$http.$patch<T>(path, payload, options)
  }

  private async delete<T>(path: string, options: Options = {}): Promise<T> {
    await this.setTokenOnRequest()
    return await this._$http.$delete<T>(path, options)
  }

  private async setTokenOnRequest() {
    const currentUser = firebaseapp.auth().currentUser
    if (!currentUser) {
      throw new Error('user is not set')
    }
    const token = await currentUser.getIdToken()
    this.setToken(token)
  }
}

export default plugin
