import { useContext } from '@nuxtjs/composition-api'
import { InjectionKey, reactive, toRefs } from '@vue/composition-api'

export interface Label {
  id: string
  title: string
  color: string
  position: number
}

export type LabelsStore = ReturnType<typeof labelsStore>
export const LabelsStoreKey: InjectionKey<LabelsStore> = Symbol('LabelsStore')

export default function labelsStore() {
  const { $api } = useContext()

  const state = reactive({
    labels: [] as Label[],
  })

  const fetchLabels = async () => {
    const response = await $api.getLabels()
    const labels: Label[] = response.data.map((l) => {
      return {
        id: l.id,
        title: l.attributes.title,
        color: l.attributes.color,
        position: l.attributes.position,
      }
    })
    state.labels = labels
  }

  return {
    ...toRefs(state),
    fetchLabels,
  }
}
