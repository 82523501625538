








import { defineComponent, inject, toRefs } from '@nuxtjs/composition-api'
import { AppStore, AppStoreKey } from '~/store/app'

export default defineComponent({
  setup() {
    const { state, toggleDrawer } = inject(AppStoreKey) as AppStore
    const { drawerMini } = toRefs(state)

    return {
      drawerMini,
      toggleDrawer,
    }
  },
})
