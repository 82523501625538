/* eslint-disable camelcase */
import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'
import { formatUserName } from '~/utils/user'

export interface Checksheet {
  organizationId: string
  id: string
  name: string
  firstCreatorName: string | undefined
  lastUpdaterName: string | undefined
  checklistId: string | undefined
  checklistTitle: string | undefined
  createdAt: string
  updatedAt: string
}

export type ChecksheetsStore = ReturnType<typeof checksheetsStore>
export const ChecksheetsStoreKey: InjectionKey<ChecksheetsStore> = Symbol(
  'ChecksheetStoreKey'
)

export default function checksheetsStore() {
  const { $api } = useContext()

  const state = reactive({
    checksheets: [] as Checksheet[],
    totalCount: 0,
  })

  const isUser = (
    entity: UserData.User | ChecklistData.Checklist
  ): entity is UserData.User => entity.type === 'users'

  const isChecklist = (
    entity: UserData.User | ChecklistData.Checklist
  ): entity is ChecklistData.Checklist => entity.type === 'checklists'

  const fetchChecksheets = async (
    organizationId: string,
    page: number = 1,
    itemsPerPage: number = 10
  ) => {
    const response = await $api.getChecksheetsFromOrganization(
      organizationId,
      page,
      itemsPerPage
    )
    const checksheets: Checksheet[] = response.data.map((c) => {
      const firstCreator = response.included
        ?.filter(isUser)
        .find((i) => i.id === c.relationships.first_creator.data?.id)
      const lastUpdater = response.included
        ?.filter(isUser)
        .find((i) => i.id === c.relationships.last_updater.data?.id)
      const checklist = response.included
        ?.filter(isChecklist)
        .find((i) => i.id === c.relationships.checklist.data?.id)
      const { name, created_at, updated_at } = c.attributes
      return {
        organizationId,
        id: c.id,
        name,
        firstCreatorName: formatUserName(firstCreator),
        lastUpdaterName: formatUserName(lastUpdater),
        checklistId: checklist?.id,
        checklistTitle: checklist?.attributes.title,
        createdAt: formatDateTimeString(created_at),
        updatedAt: formatDateTimeString(updated_at),
      }
    })
    state.checksheets = checksheets
    state.totalCount = response.meta.record_count
  }

  return {
    ...toRefs(state),
    fetchChecksheets,
  }
}
