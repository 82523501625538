import { Plugin } from '@nuxt/types'
import firebase from 'firebase/app'
import 'firebase/auth'

// eslint-disable-next-line import/no-mutable-exports
export let firebaseapp: typeof firebase
const plugin: Plugin = (context) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(context.$config.firebase)
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    firebaseapp = firebase
  }
}

export default plugin
export type AuthUser = firebase.User
