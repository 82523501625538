import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'

/* eslint-disable camelcase */
export interface Organization {
  id: string
  name: string
  createdAt: string
  updatedAt: string
}

export default function organizationStore() {
  const { $api } = useContext()

  const state = reactive({
    organization: null as Organization | null,
  })

  const fetchOrganization = async (id: string) => {
    const response = await $api.getOrganization(id)
    const { name, created_at, updated_at } = response.data.attributes
    const organization = {
      id: response.data.id,
      name,
      createdAt: formatDateTimeString(created_at),
      updatedAt: formatDateTimeString(updated_at),
    }
    state.organization = organization
  }

  return {
    ...toRefs(state),
    fetchOrganization,
  }
}

export type OrganizationStore = ReturnType<typeof organizationStore>
export const OrganizationKey: InjectionKey<OrganizationStore> = Symbol(
  'OrganizationStore'
)
