import { InjectionKey, reactive } from '@nuxtjs/composition-api'

export interface PageLink {
  icon: string
  title: string
  to: string
  avatar?: string
}

export interface PageLinkGroup {
  title: string
  links: PageLink[]
  avatar?: string
  icon?: string
}

export interface BreadcrumbItem {
  text: string
  disabled?: boolean
  href?: string
}

export default function appStore() {
  const state = reactive({
    drawer: true,
    drawerMini: false,
    pageLinks: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/',
      },
      {
        icon: 'mdi-account-circle',
        title: 'ユーザー管理',
        to: '/users',
      },
      {
        icon: 'mdi-tag',
        title: 'アセットカテゴリ一覧',
        to: '/asset-categories',
      },
      {
        icon: 'mdi-label',
        title: 'ラベル一覧',
        to: '/labels',
      },
      {
        icon: 'mdi-domain',
        title: '組織一覧',
        to: '/organizations',
      },
      {
        icon: 'mdi-beaker-check-outline',
        title: 'チェックリスト一覧',
        to: '/checklists',
      },
    ] as (PageLink | PageLinkGroup)[],
    currentGradient: 0,
    gradients: [
      'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
      'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
    ],
    currentImage: 0,
    images: [
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
    ],
    pageTitle: '',
    breadcrumbs: [] as BreadcrumbItem[],
  })

  const toggleDrawer = () => {
    state.drawerMini = !state.drawerMini
  }

  return {
    state,
    toggleDrawer,
  }
}

export type AppStore = ReturnType<typeof appStore>
export const AppStoreKey: InjectionKey<AppStore> = Symbol('AppStore')
