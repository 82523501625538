













import { defineComponent, inject } from '@nuxtjs/composition-api'
import { AlertStore, AlertStoreKey } from '~/store/alert'

export default defineComponent({
  setup() {
    const { open, timeout, color, text } = inject(AlertStoreKey) as AlertStore

    return {
      open,
      timeout,
      color,
      text,
    }
  },
})
