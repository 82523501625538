













import {
  defineComponent,
  useContext,
  inject,
  computed,
} from '@nuxtjs/composition-api'
import { AppMenuItem } from '../common/AppMenu.vue'
import { UserStore, UserStoreKey } from '~/store/user'

export default defineComponent({
  setup() {
    const {
      redirect,
      $auth: { logout, user },
    } = useContext()
    const { name, isNameUnSet } = inject(UserStoreKey) as UserStore
    const screenName = computed<string>(() => {
      if (name.value) {
        return name.value
      } else if (user.value) {
        return '名称未設定'
      } else {
        return '未ログイン'
      }
    })

    const handleLogout = () => {
      logout().then(() => redirect('/login'))
    }

    const menu: AppMenuItem[] = [
      {
        text: 'プロフィール設定',
        to: '/me',
      },
      {
        divider: true,
      },
      {
        text: 'ログアウト',
        click: handleLogout,
      },
    ]

    return {
      screenName,
      isNameUnSet,
      menu,
    }
  },
})
