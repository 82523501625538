/* eslint-disable camelcase */
import { useContext } from '@nuxtjs/composition-api'
import { InjectionKey, reactive, toRefs } from '@vue/composition-api'
import colors from 'vuetify/lib/util/colors'
import { findService, Service, ServiceId } from '~/constants/services'
import { formatDateString, formatDateTimeString } from '~/utils/datetime'

interface MonitoringsheetStat {
  all: number
  positive: number
  neutral: number
  negative: number
  mixed: number
  all_sentences: number
  positive_sentences: number
  neutral_sentences: number
  negative_sentences: number
  mixed_sentences: number
}

export interface MonitoringsheetMonitoringKeyword {
  id: string
  position: number
  service: Service | undefined
  displayName: string
  query: string
}

type ServiceStat = { [serviceId: string]: MonitoringsheetStat }
type KeywordStat = { [monitoringsheetKeywordId: string]: MonitoringsheetStat }

export interface Monitoringsheet {
  organizationId: string | undefined
  organizationName: string | undefined
  monitoringsheetKeywords: MonitoringsheetMonitoringKeyword[]
  id: string
  name: string
  description: string | undefined
  stats: {
    services: ServiceStat
    keywords: KeywordStat
  }
  services: Service[]
  fromDate: string
  toDate: string
  canRetranscribe: boolean
  createdAt: string
  updatedAt: string
}

export type MonitroingsheetStore = ReturnType<typeof monitoringsheetStore>
export const MonitoringsheetStoreKey: InjectionKey<MonitroingsheetStore> = Symbol(
  'MonitoringsheetStore'
)

/* constants */
export const labels: (keyof MonitoringsheetStat)[] = [
  'positive',
  'neutral',
  'mixed',
  'negative',
]
export const labelColors = [
  colors.blue.base,
  colors.green.base,
  colors.yellow.darken1,
  colors.red.base,
]

export default function monitoringsheetStore() {
  const { $api } = useContext()

  const state = reactive({
    monitoringsheet: undefined as Monitoringsheet | undefined,
  })

  const isOrganization = (
    entity: JSONApi.Entity<unknown>
  ): entity is OrganizationData.Organization => entity.type === 'organizations'

  const isMonitoringsheetKeyword = (
    entity: JSONApi.Entity<unknown>
  ): entity is MonitoringsheetMonitoringKeywordData.MonitoringsheetMonitoringKeyword<'monitoring_keyword'> =>
    entity.type === 'monitoringsheet_monitoring_keywords'

  const isMonitoringKeyword = (
    entity: JSONApi.Entity<unknown>
  ): entity is MonitoringKeywordData.MonitoringKeyword =>
    entity.type === 'monitoring_keywords'

  const fetchMonitoringsheet = async (monitoringsheetId: string) => {
    const response = await $api.getMonitoringsheet<
      'organization' | 'monitoringsheet_monitoring_keywords',
      MonitoringKeywordData.MonitoringKeyword
    >(monitoringsheetId, [
      'organization',
      'monitoringsheet_monitoring_keywords',
      'monitoringsheet_monitoring_keywords.monitoring_keyword',
    ])
    const organization = response.included
      ?.filter(isOrganization)
      .find((i) => i.id === response.data.relationships.organization.data?.id)
    const monitoringsheetKeywords: MonitoringsheetMonitoringKeyword[] =
      response.included?.filter(isMonitoringsheetKeyword).map((k) => {
        const monitoringKeyword = response.included
          ?.filter(isMonitoringKeyword)
          .find((i) => i.id === k.relationships.monitoring_keyword.data?.id)
        const serviceId = monitoringKeyword?.attributes.service
        return {
          id: k.id,
          position: k.attributes.position,
          service: serviceId ? findService(serviceId) : undefined,
          query: k.attributes.monitoring_keyword_query,
          displayName:
            k.attributes.monitoring_keyword_display_name ??
            k.attributes.monitoring_keyword_query,
        }
      }) ?? []
    const {
      name,
      description,
      from_date,
      to_date,
      can_retranscribe,
      created_at,
      updated_at,
    } = response.data.attributes
    const stats: Monitoringsheet['stats'] = (function () {
      const services: ServiceStat = {}
      const keywords: KeywordStat = {}
      response.data.attributes.stats.forEach((stat) => {
        const baseStat = {
          all: stat.all_count,
          positive: stat.positive_count,
          neutral: stat.neutral_count,
          negative: stat.negative_count,
          mixed: stat.mixed_count,
          all_sentences: stat.all_sentences_count,
          positive_sentences: stat.positive_sentences_count,
          neutral_sentences: stat.neutral_sentences_count,
          negative_sentences: stat.negative_sentences_count,
          mixed_sentences: stat.mixed_sentences_count,
        }
        if (stat.type === 'MonitoringsheetStatService') {
          services[stat.service] = baseStat
        } else {
          keywords[stat.monitoringsheet_monitoring_keyword_id] = baseStat
        }
      })
      return {
        services,
        keywords,
      }
    })()
    const services = (Object.keys(stats.services).filter(
      (s) => s !== 'total'
    ) as ServiceId[]).map(findService)
    const monitoringsheet: Monitoringsheet = {
      id: monitoringsheetId,
      name,
      description,
      stats,
      services,
      organizationId: organization?.id,
      organizationName: organization?.attributes.name,
      monitoringsheetKeywords,
      fromDate: formatDateString(from_date),
      toDate: formatDateString(to_date),
      canRetranscribe: can_retranscribe,
      createdAt: formatDateTimeString(created_at),
      updatedAt: formatDateTimeString(updated_at),
    }
    state.monitoringsheet = monitoringsheet
  }

  return {
    ...toRefs(state),
    fetchMonitoringsheet,
  }
}
