import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _797a99f2 = () => interopDefault(import('../pages/asset-categories/index.vue' /* webpackChunkName: "pages/asset-categories/index" */))
const _62e501e6 = () => interopDefault(import('../pages/checklists/index.vue' /* webpackChunkName: "pages/checklists/index" */))
const _354e06d8 = () => interopDefault(import('../pages/labels/index.vue' /* webpackChunkName: "pages/labels/index" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _40af0c2e = () => interopDefault(import('../pages/me.vue' /* webpackChunkName: "pages/me" */))
const _a1470706 = () => interopDefault(import('../pages/organizations/index.vue' /* webpackChunkName: "pages/organizations/index" */))
const _5cda5e36 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _377645ce = () => interopDefault(import('../pages/checklists/_id.vue' /* webpackChunkName: "pages/checklists/_id" */))
const _3bd4c678 = () => interopDefault(import('../pages/checksheets/_id/index.vue' /* webpackChunkName: "pages/checksheets/_id/index" */))
const _0249fec0 = () => interopDefault(import('../pages/monitoringsheets/_id/index.vue' /* webpackChunkName: "pages/monitoringsheets/_id/index" */))
const _582d0bb6 = () => interopDefault(import('../pages/organizations/_id.vue' /* webpackChunkName: "pages/organizations/_id" */))
const _ac57c29c = () => interopDefault(import('../pages/checksheets/_id/report.vue' /* webpackChunkName: "pages/checksheets/_id/report" */))
const _dfa80794 = () => interopDefault(import('../pages/monitoringsheets/_id/report.vue' /* webpackChunkName: "pages/monitoringsheets/_id/report" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/asset-categories",
    component: _797a99f2,
    name: "asset-categories"
  }, {
    path: "/checklists",
    component: _62e501e6,
    name: "checklists"
  }, {
    path: "/labels",
    component: _354e06d8,
    name: "labels"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/me",
    component: _40af0c2e,
    name: "me"
  }, {
    path: "/organizations",
    component: _a1470706,
    name: "organizations"
  }, {
    path: "/users",
    component: _5cda5e36,
    name: "users"
  }, {
    path: "/checklists/:id",
    component: _377645ce,
    name: "checklists-id"
  }, {
    path: "/checksheets/:id",
    component: _3bd4c678,
    name: "checksheets-id"
  }, {
    path: "/monitoringsheets/:id",
    component: _0249fec0,
    name: "monitoringsheets-id"
  }, {
    path: "/organizations/:id",
    component: _582d0bb6,
    name: "organizations-id"
  }, {
    path: "/checksheets/:id?/report",
    component: _ac57c29c,
    name: "checksheets-id-report"
  }, {
    path: "/monitoringsheets/:id?/report",
    component: _dfa80794,
    name: "monitoringsheets-id-report"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
