/* eslint-disable camelcase */
import { useContext } from '@nuxtjs/composition-api'
import { InjectionKey, reactive, toRefs } from '@vue/composition-api'
import { formatDateString, formatDateTimeString } from '~/utils/datetime'
import { formatUserName } from '~/utils/user'

export interface Monitoringsheet {
  organizationId: string
  id: string
  name: string
  firstCreatorName: string | undefined
  lastUpdaterName: string | undefined
  fromDate: string
  toDate: string
  canRetranscribe: boolean
  createdAt: string
  updatedAt: string
}

export type MonitoringsheetsStore = ReturnType<typeof monitoringsheetsStore>
export const MonitoringsheetsStoreKey: InjectionKey<MonitoringsheetsStore> = Symbol(
  'MonitoringsheetsStore'
)
export default function monitoringsheetsStore() {
  const { $api } = useContext()

  const state = reactive({
    monitoringsheets: [] as Monitoringsheet[],
    totalCount: 0,
  })

  const fetchMonitoringsheets = async (
    organizationId: string,
    page: number = 1,
    itemsPerPage: number = 10
  ) => {
    const response = await $api.getMonitoringsheetsFromOrganization(
      organizationId,
      page,
      itemsPerPage
    )
    const monitoringsheets: Monitoringsheet[] = response.data.map((s) => {
      const firstCreator = response.included?.find(
        (i) => i.id === s.relationships.first_creator.data?.id
      )
      const lastUpdater = response.included?.find(
        (i) => i.id === s.relationships.last_updater.data?.id
      )
      const {
        name,
        description,
        from_date,
        to_date,
        can_retranscribe,
        created_at,
        updated_at,
      } = s.attributes
      return {
        id: s.id,
        name,
        description,
        organizationId,
        fromDate: formatDateString(from_date),
        toDate: formatDateString(to_date),
        canRetranscribe: can_retranscribe,
        createdAt: formatDateTimeString(created_at),
        updatedAt: formatDateTimeString(updated_at),
        firstCreatorName: formatUserName(firstCreator),
        lastUpdaterName: formatUserName(lastUpdater),
      }
    })
    state.monitoringsheets = monitoringsheets
    state.totalCount = response.meta.record_count
  }

  return {
    ...toRefs(state),
    fetchMonitoringsheets,
  }
}
