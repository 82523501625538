









































import {
  computed,
  defineComponent,
  PropType,
  ref,
} from '@nuxtjs/composition-api'
import { PageLink, PageLinkGroup } from '~/store/app'

export default defineComponent({
  props: {
    group: {
      type: Object as PropType<PageLinkGroup>,
      default: () => {},
    },
  },
  setup(props) {
    const gradient = ref(1)
    const groupTitle = computed(() => {
      return genGroupTitle(props.group.links)
    })

    const title = computed(() => {
      const matches = props.group.title.match(/\b(\w)/g)
      return matches?.join('')
    })

    const isPageLinkGroup = (
      item: PageLink | PageLinkGroup
    ): item is PageLinkGroup => {
      return !!(item as PageLinkGroup).links
    }

    const genGroupTitle = (items: (PageLink | PageLinkGroup)[]) => {
      return items
        .reduce((acc: string[], item) => {
          acc.push(
            isPageLinkGroup(item)
              ? genGroupTitle(item.links)
              : item.to.slice(1, -1)
          )
          return acc
        }, [])
        .join('|')
    }

    return {
      gradient,
      groupTitle,
      title,
      genGroupTitle,
    }
  },
})
