export function countUpArray(length: number, start: number = 0): number[] {
  return Array.from({ length }, (_, i) => i + start)
}

export function isFullFill<T>(arg: T | undefined | null): arg is T {
  return !!arg
}

export function uniq<T>(arr: T[]): T[] {
  return [...new Set(arr)]
}
