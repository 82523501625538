/* eslint-disable camelcase */
import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'

export interface Checksheet {
  organizationId: string | undefined
  organizationName: string | undefined
  checklistId: string | undefined
  checklistTitle: string | undefined
  checklistVersion: string
  id: string
  name: string
  advice: string | null
  description: string | null
  createdAt: string
  updatedAt: string
}

const isOrganization = (
  entity: OrganizationData.Organization | ChecklistData.Checklist
): entity is OrganizationData.Organization => entity.type === 'organizations'

const isChecklist = (
  entity: OrganizationData.Organization | ChecklistData.Checklist
): entity is ChecklistData.Checklist => entity.type === 'checklists'

export type ChecksheetStore = ReturnType<typeof checksheetStore>
export const ChecksheetStoreKey: InjectionKey<ChecksheetStore> = Symbol(
  'ChecksheetStore'
)

export default function checksheetStore() {
  const { $api } = useContext()

  const state = reactive({
    checksheet: undefined as Checksheet | undefined,
  })

  const fetchChecksheet = async (checksheetId: string) => {
    const response = await $api.getChecksheet(checksheetId, [
      'organization',
      'checklist',
    ])
    const organization = response.included
      ?.filter(isOrganization)
      .find((i) => i.id === response.data.relationships.organization.data?.id)
    const checklist = response.included
      ?.filter(isChecklist)
      .find((i) => i.id === response.data.relationships.checklist.data?.id)
    const {
      name,
      checklist_version,
      advice,
      description,
      created_at,
      updated_at,
    } = response.data.attributes
    const checksheet: Checksheet = {
      organizationId: organization?.id,
      organizationName: organization?.attributes.name,
      checklistId: checklist?.id,
      checklistTitle: checklist?.attributes.title,
      checklistVersion: formatDateTimeString(checklist_version),
      description,
      advice,
      id: response.data.id,
      name,
      createdAt: formatDateTimeString(created_at),
      updatedAt: formatDateTimeString(updated_at),
    }
    state.checksheet = checksheet
  }

  return {
    ...toRefs(state),
    fetchChecksheet,
  }
}
