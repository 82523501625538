/* eslint-disable camelcase */
import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'
import { formatUserName } from '~/utils/user'

module Dashboard {
  export interface Checksheet {
    id: string
    name: string
    lastUpdaterName: string | undefined
    organizationId: string | undefined
    organizationName: string | undefined
    updatedAt: string
  }
  export interface Monitoringsheet {
    id: string
    name: string
    lastUpdaterName: string | undefined
    organizationId: string | undefined
    organizationName: string | undefined
    updatedAt: string
  }
  export interface Organization {
    id: string
    name: string
    createdAt: string
  }
}

export type DashboardStore = ReturnType<typeof dashboardStore>
export const DashboardStoreKey: InjectionKey<DashboardStore> = Symbol(
  'DashboardStoreKey'
)

const isUser = (
  entity: UserData.User | OrganizationData.Organization
): entity is UserData.User => entity.type === 'users'

const isOrganization = (
  entity: UserData.User | OrganizationData.Organization
): entity is OrganizationData.Organization => entity.type === 'organizations'

export default function dashboardStore() {
  const { $api } = useContext()

  const state = reactive({
    checksheets: [] as Dashboard.Checksheet[],
    organizations: [] as Dashboard.Organization[],
    monitoringsheets: [] as Dashboard.Monitoringsheet[],
  })

  const fetchRecentChecklists = async () => {
    const response = await $api.getChecksheets({
      size: 5,
      includes: ['last_updater', 'organization'],
    })
    state.checksheets = response.data.map((r) => {
      const user = response.included
        ?.filter(isUser)
        .find((i) => i.id === r.relationships.last_updater.data?.id)
      const organization = response.included
        ?.filter(isOrganization)
        .find((i) => i.id === r.relationships.organization.data?.id)
      const { name, updated_at } = r.attributes
      return {
        id: r.id,
        name,
        updatedAt: formatDateTimeString(updated_at),
        organizationId: organization?.id,
        organizationName: organization?.attributes.name,
        lastUpdaterName: formatUserName(user),
      }
    })
  }

  const fetchRecentMonitoringsheets = async () => {
    const response = await $api.getMonitoringsheets({
      size: 5,
      includes: ['last_updater', 'organization'],
    })
    const monitoringsheets: Dashboard.Monitoringsheet[] = response.data.map(
      (r) => {
        const user = response.included
          ?.filter(isUser)
          .find((i) => i.id === r.relationships.last_updater.data?.id)
        const organization = response.included
          ?.filter(isOrganization)
          .find((i) => i.id === r.relationships.organization.data?.id)
        const { name, updated_at } = r.attributes
        return {
          id: r.id,
          name,
          updatedAt: formatDateTimeString(updated_at),
          organizationId: organization?.id,
          organizationName: organization?.attributes.name,
          lastUpdaterName: formatUserName(user),
        }
      }
    )
    state.monitoringsheets = monitoringsheets
  }

  const fetchRecentOrganizations = async () => {
    const response = await $api.getOrganizations(1, 5)
    state.organizations = response.data.map((r) => {
      const { name, created_at } = r.attributes
      return {
        id: r.id,
        name,
        createdAt: formatDateTimeString(created_at),
      }
    })
  }

  return {
    ...toRefs(state),
    fetchRecentChecklists,
    fetchRecentMonitoringsheets,
    fetchRecentOrganizations,
  }
}
