






































import { defineComponent, inject, toRefs } from '@nuxtjs/composition-api'
import { AppStore, AppStoreKey } from '~/store/app'

export default defineComponent({
  setup() {
    const { state } = inject(AppStoreKey) as AppStore
    return {
      ...toRefs(state),
    }
  },
})
