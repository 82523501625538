































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { PageLink } from '~/store/app'

export default defineComponent({
  props: {
    link: {
      type: Object as PropType<PageLink>,
      default: () => {},
    },
  },
  setup(props) {
    const title = () => {
      const matches = props.link.title.match(/\b(\w)/g)
      return matches?.join('')
    }
    return {
      title,
    }
  },
})
