import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import _ from 'lodash'
import { Service, services } from '~/constants/services'
import { isFullFill } from '~/utils/array'

export type ReputationSentencesStore = ReturnType<
  typeof reputationSentencesStore
>

export const ReputationSentencesStoreKey: InjectionKey<ReputationSentencesStore> = Symbol(
  'ReputationSentencesStore'
)

export interface GoogleMapMetaData {
  placeName: string
}

export type ReportIncluded = ReputationSentenceData.ReportIncluded

export interface ReputationSentence {
  id: string
  service: Service
  serviceIdentity: string
  preText: string
  sentenceText: string
  postText: string
  source: string
  monitoringKeyword: string | null
  sentiment: 'positive' | 'negative' | 'mixed' | 'neutral' | null
  positive: number | null
  neutral: number | null
  negative: number | null
  postedAt: string
  reportIncluded: ReportIncluded
}

export interface ReputationSentenceServiceGroup {
  service: Service
  positiveSentences: ReputationSentence[]
  negativeSentences: ReputationSentence[]
}

const isMonitoringsheetKeyword = (
  arg: JSONApi.Entity<unknown>
): arg is MonitoringsheetMonitoringKeywordData.MonitoringsheetMonitoringKeyword =>
  arg.type === 'monitoringsheet_monitoring_keywords'

const isReputationComment = (
  arg: JSONApi.Entity<unknown>
): arg is ReputationCommentData.ReputationComment =>
  arg.type === 'reputation_comments'

const isGoogleMapComment = (
  arg: ReputationCommentData.ReputationComment
): arg is ReputationCommentData.ReputationComment<'google_map'> =>
  arg.attributes.service === 'google_map'

const isYoutubeChannelComment = (
  arg: ReputationCommentData.ReputationComment
): arg is ReputationCommentData.ReputationComment<'youtube_channel_comment'> =>
  arg.attributes.service === 'youtube_channel_comment'

const buildSource = (
  c: ReputationCommentData.ReputationComment,
  k?: MonitoringsheetMonitoringKeywordData.MonitoringsheetMonitoringKeyword
): string => {
  if (isGoogleMapComment(c)) {
    return c.attributes.metadata.place_name
  } else if (isYoutubeChannelComment(c)) {
    return c.attributes.metadata.video_title
  } else {
    return k?.attributes.monitoring_keyword_query ?? ''
  }
}

const filterSentences = (
  sentences: ReputationSentence[],
  sentiment: 'positive' | 'negative'
): ReputationSentence[] => {
  let filtered: ReputationSentence[]
  if (sentiment === 'positive') {
    filtered = _.sortBy(sentences, (s) => s.positive).filter(
      (s) => s.positive && s.positive >= 0.5
    )
  } else {
    filtered = _.sortBy(sentences, (s) => s.negative).filter(
      (s) => s.negative && s.negative >= 0.5
    )
  }
  return filtered.reverse()
}

export default function reputationSentencesStore() {
  const { $api } = useContext()

  const state = reactive({
    groups: [] as ReputationSentenceServiceGroup[],
  })

  const fetch = async (monitoringsheetId: string) => {
    const response = await $api.getReputationSentences(monitoringsheetId, [
      'reputation_comment',
      'monitoringsheet_monitoring_keyword',
    ])

    state.groups = services
      .map((service) => {
        const sentenceResponses = response.data.filter(
          (r) => r.attributes.service === service.id
        )
        if (sentenceResponses.length === 0) {
          return null
        }

        const sentences = sentenceResponses.map((s) => {
          const monitoringsheetKeyword = response.included
            ?.filter(isMonitoringsheetKeyword)
            .find(
              (i) =>
                i.id ===
                s.relationships.monitoringsheet_monitoring_keyword.data?.id
            )
          const reputationComment = response.included
            ?.filter(isReputationComment)
            .find((i) => i.id === s.relationships.reputation_comment.data?.id)
          const {
            sentiment,
            positive,
            neutral,
            negative,
            posted_at: postedAt,
            report_included: reportIncluded,
          } = s.attributes
          const offset = s.attributes.offset ?? 0
          const length = s.attributes.length ?? 0
          const {
            text: fullText,
            service_identity: serviceIdentity,
          } = reputationComment!.attributes
          return {
            id: s.id,
            service,
            serviceIdentity,
            preText: fullText.substr(0, offset),
            sentenceText: fullText.substr(offset, length),
            postText: fullText.substr(offset + length),
            source: buildSource(reputationComment!, monitoringsheetKeyword),
            monitoringKeyword:
              monitoringsheetKeyword?.attributes.monitoring_keyword_query ??
              null,
            sentiment,
            positive,
            neutral,
            negative,
            postedAt,
            reportIncluded,
          }
        })
        return {
          service,
          positiveSentences: filterSentences(sentences, 'positive'),
          negativeSentences: filterSentences(sentences, 'negative'),
        }
      })
      .filter(isFullFill)
  }

  return {
    ...toRefs(state),
    fetch,
  }
}
