import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'

export interface AssetCategory {
  id: string
  name: string
  position: number
}

export default function assetCategoriesStore() {
  const { $api } = useContext()

  const state = reactive({
    assetCategories: [] as AssetCategory[],
  })

  const fetchAssetCategories = async () => {
    const response = await $api.getAssetCategories()
    const assetCategories: AssetCategory[] = response.data.map((c) => {
      return {
        id: c.id,
        name: c.attributes.name,
        position: c.attributes.position,
      }
    })
    state.assetCategories = assetCategories
  }

  const createAssetCategory = async (categoryName: string) => {
    await $api.createAssetCategory(categoryName)
  }

  const editAssetCategory = async (
    id: string,
    attributes: { name: string; position: number }
  ) => {
    await $api.updateAssetCategory(id, attributes)
  }

  const deleteAssetCategory = async (id: string) => {
    await $api.deleteAssetCategory(id)
  }

  return {
    ...toRefs(state),
    fetchAssetCategories,
    createAssetCategory,
    editAssetCategory,
    deleteAssetCategory,
  }
}

export type AssetCategoriesStore = ReturnType<typeof assetCategoriesStore>
export const AssetCategoriesStoreKey: InjectionKey<AssetCategoriesStore> = Symbol(
  'AssetCategories'
)
