/* eslint-disable camelcase */
import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'

export interface Organization {
  id: string
  name: string
  createdAt: string
  updatedAt: string
}

export default function organizationsStore() {
  const { $api } = useContext()
  const state = reactive({
    organizations: [] as Organization[],
    totalCount: 0,
  })

  const fetchOrganizations = async (
    page: number = 1,
    itemsPerPage: number = 10
  ) => {
    const response = await $api.getOrganizations(page, itemsPerPage)
    const organizations = response.data.map((o) => {
      const { name, created_at, updated_at } = o.attributes
      return {
        id: o.id,
        name,
        createdAt: formatDateTimeString(created_at),
        updatedAt: formatDateTimeString(updated_at),
      }
    })
    state.organizations = organizations
    state.totalCount = response.meta.record_count
  }

  return {
    ...toRefs(state),
    fetchOrganizations,
  }
}

export type OrganizationsStore = ReturnType<typeof organizationsStore>
export const OrganizationsStoreKey: InjectionKey<OrganizationsStore> = Symbol(
  'Organizations'
)
