import { render, staticRenderFns } from "./Topbar.vue?vue&type=template&id=527fe81f&"
import script from "./Topbar.vue?vue&type=script&lang=ts&"
export * from "./Topbar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSidebarToggle: require('/vercel/path0/components/app/SidebarToggle.vue').default,AppDevNotify: require('/vercel/path0/components/app/DevNotify.vue').default,AppAccount: require('/vercel/path0/components/app/Account.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBreadcrumbs,VSpacer})
