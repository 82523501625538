import { Plugin } from '@nuxt/types'

const plugin: Plugin = ({ $api, $auth }) => {
  $api.$http.onError((error) => {
    if (!$auth.isLoginProcess && error.response?.status === 401) {
      $auth.logout()
    }
  })
}

export default plugin
