import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  ActionButton: () => import('../../components/common/ActionButton.vue' /* webpackChunkName: "components/action-button" */).then(c => wrapFunctional(c.default || c)),
  AddButton: () => import('../../components/common/AddButton.vue' /* webpackChunkName: "components/add-button" */).then(c => wrapFunctional(c.default || c)),
  AppMenu: () => import('../../components/common/AppMenu.vue' /* webpackChunkName: "components/app-menu" */).then(c => wrapFunctional(c.default || c)),
  AppMenuItem: () => import('../../components/common/AppMenuItem.vue' /* webpackChunkName: "components/app-menu-item" */).then(c => wrapFunctional(c.default || c)),
  AssetCategoriesSelect: () => import('../../components/common/AssetCategoriesSelect.vue' /* webpackChunkName: "components/asset-categories-select" */).then(c => wrapFunctional(c.default || c)),
  Box: () => import('../../components/common/Box.vue' /* webpackChunkName: "components/box" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetAssetsSelect: () => import('../../components/common/ChecksheetAssetsSelect.vue' /* webpackChunkName: "components/checksheet-assets-select" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetRuleCheckSelect: () => import('../../components/common/ChecksheetRuleCheckSelect.vue' /* webpackChunkName: "components/checksheet-rule-check-select" */).then(c => wrapFunctional(c.default || c)),
  EmailTextField: () => import('../../components/common/EmailTextField.vue' /* webpackChunkName: "components/email-text-field" */).then(c => wrapFunctional(c.default || c)),
  OuterLink: () => import('../../components/common/OuterLink.vue' /* webpackChunkName: "components/outer-link" */).then(c => wrapFunctional(c.default || c)),
  PasswordTextField: () => import('../../components/common/PasswordTextField.vue' /* webpackChunkName: "components/password-text-field" */).then(c => wrapFunctional(c.default || c)),
  RuleLabel: () => import('../../components/common/RuleLabel.vue' /* webpackChunkName: "components/rule-label" */).then(c => wrapFunctional(c.default || c)),
  Stat: () => import('../../components/common/Stat.vue' /* webpackChunkName: "components/stat" */).then(c => wrapFunctional(c.default || c)),
  StatArrow: () => import('../../components/common/StatArrow.vue' /* webpackChunkName: "components/stat-arrow" */).then(c => wrapFunctional(c.default || c)),
  StatHelpText: () => import('../../components/common/StatHelpText.vue' /* webpackChunkName: "components/stat-help-text" */).then(c => wrapFunctional(c.default || c)),
  StatLabel: () => import('../../components/common/StatLabel.vue' /* webpackChunkName: "components/stat-label" */).then(c => wrapFunctional(c.default || c)),
  StatNumber: () => import('../../components/common/StatNumber.vue' /* webpackChunkName: "components/stat-number" */).then(c => wrapFunctional(c.default || c)),
  DonutChart: () => import('../../components/common/chart/DonutChart.vue' /* webpackChunkName: "components/donut-chart" */).then(c => wrapFunctional(c.default || c)),
  StackedBarChart: () => import('../../components/common/chart/StackedBarChart.vue' /* webpackChunkName: "components/stacked-bar-chart" */).then(c => wrapFunctional(c.default || c)),
  AppDialog: () => import('../../components/common/dialog/AppDialog.vue' /* webpackChunkName: "components/app-dialog" */).then(c => wrapFunctional(c.default || c)),
  DeleteDialog: () => import('../../components/common/dialog/DeleteDialog.vue' /* webpackChunkName: "components/delete-dialog" */).then(c => wrapFunctional(c.default || c)),
  InlineEditDialog: () => import('../../components/common/dialog/InlineEditDialog.vue' /* webpackChunkName: "components/inline-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  NotPrintSection: () => import('../../components/common/report/NotPrintSection.vue' /* webpackChunkName: "components/not-print-section" */).then(c => wrapFunctional(c.default || c)),
  ReportFooter: () => import('../../components/common/report/ReportFooter.vue' /* webpackChunkName: "components/report-footer" */).then(c => wrapFunctional(c.default || c)),
  ReportSection: () => import('../../components/common/report/ReportSection.vue' /* webpackChunkName: "components/report-section" */).then(c => wrapFunctional(c.default || c)),
  ApiDataTable: () => import('../../components/common/table/ApiDataTable.vue' /* webpackChunkName: "components/api-data-table" */).then(c => wrapFunctional(c.default || c)),
  ReportTable: () => import('../../components/common/table/ReportTable.vue' /* webpackChunkName: "components/report-table" */).then(c => wrapFunctional(c.default || c)),
  AppAccount: () => import('../../components/app/Account.vue' /* webpackChunkName: "components/app-account" */).then(c => wrapFunctional(c.default || c)),
  AppAlert: () => import('../../components/app/Alert.vue' /* webpackChunkName: "components/app-alert" */).then(c => wrapFunctional(c.default || c)),
  AppDevNotify: () => import('../../components/app/DevNotify.vue' /* webpackChunkName: "components/app-dev-notify" */).then(c => wrapFunctional(c.default || c)),
  AppSidebar: () => import('../../components/app/Sidebar.vue' /* webpackChunkName: "components/app-sidebar" */).then(c => wrapFunctional(c.default || c)),
  AppSidebarToggle: () => import('../../components/app/SidebarToggle.vue' /* webpackChunkName: "components/app-sidebar-toggle" */).then(c => wrapFunctional(c.default || c)),
  AppTopbar: () => import('../../components/app/Topbar.vue' /* webpackChunkName: "components/app-topbar" */).then(c => wrapFunctional(c.default || c)),
  AssetCategoriesAddDialog: () => import('../../components/asset-categories/AddDialog.vue' /* webpackChunkName: "components/asset-categories-add-dialog" */).then(c => wrapFunctional(c.default || c)),
  AssetCategoriesEditDialog: () => import('../../components/asset-categories/EditDialog.vue' /* webpackChunkName: "components/asset-categories-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsChart: () => import('../../components/checklists/Chart.vue' /* webpackChunkName: "components/checklists-chart" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsCharts: () => import('../../components/checklists/Charts.vue' /* webpackChunkName: "components/checklists-charts" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsCreateDialog: () => import('../../components/checklists/CreateDialog.vue' /* webpackChunkName: "components/checklists-create-dialog" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsEditDialog: () => import('../../components/checklists/EditDialog.vue' /* webpackChunkName: "components/checklists-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsImportDialog: () => import('../../components/checklists/ImportDialog.vue' /* webpackChunkName: "components/checklists-import-dialog" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsLabelSelect: () => import('../../components/checklists/LabelSelect.vue' /* webpackChunkName: "components/checklists-label-select" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsStats: () => import('../../components/checklists/Stats.vue' /* webpackChunkName: "components/checklists-stats" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsAssetList: () => import('../../components/checksheets/AssetList.vue' /* webpackChunkName: "components/checksheets-asset-list" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsDetails: () => import('../../components/checksheets/Details.vue' /* webpackChunkName: "components/checksheets-details" */).then(c => wrapFunctional(c.default || c)),
  IndexActionMenu: () => import('../../components/index/ActionMenu.vue' /* webpackChunkName: "components/index-action-menu" */).then(c => wrapFunctional(c.default || c)),
  IndexRecentChecksheets: () => import('../../components/index/RecentChecksheets.vue' /* webpackChunkName: "components/index-recent-checksheets" */).then(c => wrapFunctional(c.default || c)),
  IndexRecentMonitoringsheets: () => import('../../components/index/RecentMonitoringsheets.vue' /* webpackChunkName: "components/index-recent-monitoringsheets" */).then(c => wrapFunctional(c.default || c)),
  IndexRecentOrganizations: () => import('../../components/index/RecentOrganizations.vue' /* webpackChunkName: "components/index-recent-organizations" */).then(c => wrapFunctional(c.default || c)),
  LabelsAddDialog: () => import('../../components/labels/AddDialog.vue' /* webpackChunkName: "components/labels-add-dialog" */).then(c => wrapFunctional(c.default || c)),
  LabelsEditDialog: () => import('../../components/labels/EditDialog.vue' /* webpackChunkName: "components/labels-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsActions: () => import('../../components/monitoringsheets/Actions.vue' /* webpackChunkName: "components/monitoringsheets-actions" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsDetails: () => import('../../components/monitoringsheets/Details.vue' /* webpackChunkName: "components/monitoringsheets-details" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsKeywordTable: () => import('../../components/monitoringsheets/KeywordTable.vue' /* webpackChunkName: "components/monitoringsheets-keyword-table" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsStats: () => import('../../components/monitoringsheets/Stats.vue' /* webpackChunkName: "components/monitoringsheets-stats" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsStatsBar: () => import('../../components/monitoringsheets/StatsBar.vue' /* webpackChunkName: "components/monitoringsheets-stats-bar" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsCreateDialog: () => import('../../components/organizations/CreateDialog.vue' /* webpackChunkName: "components/organizations-create-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsEditDialog: () => import('../../components/organizations/EditDialog.vue' /* webpackChunkName: "components/organizations-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  AppSidebarList: () => import('../../components/app/sidebar/List.vue' /* webpackChunkName: "components/app-sidebar-list" */).then(c => wrapFunctional(c.default || c)),
  AppSidebarListGroup: () => import('../../components/app/sidebar/ListGroup.vue' /* webpackChunkName: "components/app-sidebar-list-group" */).then(c => wrapFunctional(c.default || c)),
  AppSidebarListItem: () => import('../../components/app/sidebar/ListItem.vue' /* webpackChunkName: "components/app-sidebar-list-item" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsRuleCreateDialog: () => import('../../components/checklists/rule/CreateDialog.vue' /* webpackChunkName: "components/checklists-rule-create-dialog" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsRuleItem: () => import('../../components/checklists/rule/Item.vue' /* webpackChunkName: "components/checklists-rule-item" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsRuleLabelSelectDialog: () => import('../../components/checklists/rule/LabelSelectDialog.vue' /* webpackChunkName: "components/checklists-rule-label-select-dialog" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsRuleList: () => import('../../components/checklists/rule/List.vue' /* webpackChunkName: "components/checklists-rule-list" */).then(c => wrapFunctional(c.default || c)),
  ChecklistsRuleListHeader: () => import('../../components/checklists/rule/ListHeader.vue' /* webpackChunkName: "components/checklists-rule-list-header" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsChecksheetRuleChart: () => import('../../components/checksheets/checksheet-rule/Chart.vue' /* webpackChunkName: "components/checksheets-checksheet-rule-chart" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsChecksheetRuleCheckSelectDialog: () => import('../../components/checksheets/checksheet-rule/CheckSelectDialog.vue' /* webpackChunkName: "components/checksheets-checksheet-rule-check-select-dialog" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsChecksheetRuleGroupList: () => import('../../components/checksheets/checksheet-rule/GroupList.vue' /* webpackChunkName: "components/checksheets-checksheet-rule-group-list" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsChecksheetRuleGroupListHeader: () => import('../../components/checksheets/checksheet-rule/GroupListHeader.vue' /* webpackChunkName: "components/checksheets-checksheet-rule-group-list-header" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsChecksheetRuleItem: () => import('../../components/checksheets/checksheet-rule/Item.vue' /* webpackChunkName: "components/checksheets-checksheet-rule-item" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportActions: () => import('../../components/checksheets/report/Actions.vue' /* webpackChunkName: "components/checksheets-report-actions" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportAssetBar: () => import('../../components/checksheets/report/AssetBar.vue' /* webpackChunkName: "components/checksheets-report-asset-bar" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportAssetComparingBar: () => import('../../components/checksheets/report/AssetComparingBar.vue' /* webpackChunkName: "components/checksheets-report-asset-comparing-bar" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportAssetList: () => import('../../components/checksheets/report/AssetList.vue' /* webpackChunkName: "components/checksheets-report-asset-list" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportAssetStackedBar: () => import('../../components/checksheets/report/AssetStackedBar.vue' /* webpackChunkName: "components/checksheets-report-asset-stacked-bar" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportComparingChart: () => import('../../components/checksheets/report/ComparingChart.vue' /* webpackChunkName: "components/checksheets-report-comparing-chart" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportDashboard: () => import('../../components/checksheets/report/Dashboard.vue' /* webpackChunkName: "components/checksheets-report-dashboard" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportDonutChart: () => import('../../components/checksheets/report/DonutChart.vue' /* webpackChunkName: "components/checksheets-report-donut-chart" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportLabelRadar: () => import('../../components/checksheets/report/LabelRadar.vue' /* webpackChunkName: "components/checksheets-report-label-radar" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportRuleChart: () => import('../../components/checksheets/report/RuleChart.vue' /* webpackChunkName: "components/checksheets-report-rule-chart" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportRuleSheet: () => import('../../components/checksheets/report/RuleSheet.vue' /* webpackChunkName: "components/checksheets-report-rule-sheet" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportRulesTable: () => import('../../components/checksheets/report/RulesTable.vue' /* webpackChunkName: "components/checksheets-report-rules-table" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportSection: () => import('../../components/checksheets/report/Section.vue' /* webpackChunkName: "components/checksheets-report-section" */).then(c => wrapFunctional(c.default || c)),
  ChecksheetsReportSettings: () => import('../../components/checksheets/report/Settings.vue' /* webpackChunkName: "components/checksheets-report-settings" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReportCommentTable: () => import('../../components/monitoringsheets/report/CommentTable.vue' /* webpackChunkName: "components/monitoringsheets-report-comment-table" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReportDashboard: () => import('../../components/monitoringsheets/report/Dashboard.vue' /* webpackChunkName: "components/monitoringsheets-report-dashboard" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReportKeywordBar: () => import('../../components/monitoringsheets/report/KeywordBar.vue' /* webpackChunkName: "components/monitoringsheets-report-keyword-bar" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReportSection: () => import('../../components/monitoringsheets/report/Section.vue' /* webpackChunkName: "components/monitoringsheets-report-section" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReportTotalChart: () => import('../../components/monitoringsheets/report/TotalChart.vue' /* webpackChunkName: "components/monitoringsheets-report-total-chart" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReportTotalStats: () => import('../../components/monitoringsheets/report/TotalStats.vue' /* webpackChunkName: "components/monitoringsheets-report-total-stats" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReputationSentenceTable: () => import('../../components/monitoringsheets/reputation-sentence/Table.vue' /* webpackChunkName: "components/monitoringsheets-reputation-sentence-table" */).then(c => wrapFunctional(c.default || c)),
  MonitoringsheetsReputationSentenceTables: () => import('../../components/monitoringsheets/reputation-sentence/Tables.vue' /* webpackChunkName: "components/monitoringsheets-reputation-sentence-tables" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsAssetCreateDialog: () => import('../../components/organizations/asset/CreateDialog.vue' /* webpackChunkName: "components/organizations-asset-create-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsAssetEditDialog: () => import('../../components/organizations/asset/EditDialog.vue' /* webpackChunkName: "components/organizations-asset-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsAssetList: () => import('../../components/organizations/asset/List.vue' /* webpackChunkName: "components/organizations-asset-list" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsChecksheetChecklistsSelect: () => import('../../components/organizations/checksheet/ChecklistsSelect.vue' /* webpackChunkName: "components/organizations-checksheet-checklists-select" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsChecksheetEditDialog: () => import('../../components/organizations/checksheet/EditDialog.vue' /* webpackChunkName: "components/organizations-checksheet-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsChecksheetImportDialog: () => import('../../components/organizations/checksheet/ImportDialog.vue' /* webpackChunkName: "components/organizations-checksheet-import-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsChecksheetList: () => import('../../components/organizations/checksheet/List.vue' /* webpackChunkName: "components/organizations-checksheet-list" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsChecksheetSelect: () => import('../../components/organizations/checksheet/Select.vue' /* webpackChunkName: "components/organizations-checksheet-select" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringKeywordCreateDialog: () => import('../../components/organizations/monitoring-keyword/CreateDialog.vue' /* webpackChunkName: "components/organizations-monitoring-keyword-create-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringKeywordEditDialog: () => import('../../components/organizations/monitoring-keyword/EditDialog.vue' /* webpackChunkName: "components/organizations-monitoring-keyword-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringKeywordGoogleMapForm: () => import('../../components/organizations/monitoring-keyword/GoogleMapForm.vue' /* webpackChunkName: "components/organizations-monitoring-keyword-google-map-form" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringKeywordList: () => import('../../components/organizations/monitoring-keyword/List.vue' /* webpackChunkName: "components/organizations-monitoring-keyword-list" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringKeywordServicesSelect: () => import('../../components/organizations/monitoring-keyword/ServicesSelect.vue' /* webpackChunkName: "components/organizations-monitoring-keyword-services-select" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringKeywordTwitterForm: () => import('../../components/organizations/monitoring-keyword/TwitterForm.vue' /* webpackChunkName: "components/organizations-monitoring-keyword-twitter-form" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringKeywordYoutubeChannelCommentForm: () => import('../../components/organizations/monitoring-keyword/YoutubeChannelCommentForm.vue' /* webpackChunkName: "components/organizations-monitoring-keyword-youtube-channel-comment-form" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringsheetActionButton: () => import('../../components/organizations/monitoringsheet/ActionButton.vue' /* webpackChunkName: "components/organizations-monitoringsheet-action-button" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringsheetEditDialog: () => import('../../components/organizations/monitoringsheet/EditDialog.vue' /* webpackChunkName: "components/organizations-monitoringsheet-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringsheetImportDialog: () => import('../../components/organizations/monitoringsheet/ImportDialog.vue' /* webpackChunkName: "components/organizations-monitoringsheet-import-dialog" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringsheetList: () => import('../../components/organizations/monitoringsheet/List.vue' /* webpackChunkName: "components/organizations-monitoringsheet-list" */).then(c => wrapFunctional(c.default || c)),
  OrganizationsMonitoringsheetRetranscribeDialog: () => import('../../components/organizations/monitoringsheet/RetranscribeDialog.vue' /* webpackChunkName: "components/organizations-monitoringsheet-retranscribe-dialog" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
