import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { findService, Service } from '~/constants/services'

export interface MonitoringKeyword {
  id: string
  displayName: string | null
  query: string
  position: number
  service: Service
  option: any
  organizationId: string
}

export type MonitoringKeywordsStore = ReturnType<typeof monitoringKeywordsStore>
export const MonitoringKeywordsStoreKey: InjectionKey<MonitoringKeywordsStore> = Symbol(
  'MonitoringKeywordStore'
)
export default function monitoringKeywordsStore() {
  const { $api } = useContext()

  const state = reactive({
    monitoringKeywords: [] as MonitoringKeyword[],
  })

  const fetchMonitoringKeywords = async (organizationId: string) => {
    const response = await $api.getMonitoringKeywords(organizationId)
    const monitoringKeywords: MonitoringKeyword[] = response.data.map((k) => ({
      id: k.id,
      displayName: k.attributes.display_name,
      query: k.attributes.query,
      position: k.attributes.position,
      service: findService(k.attributes.service),
      option: k.attributes.option,
      organizationId,
    }))

    state.monitoringKeywords = monitoringKeywords
  }

  return {
    ...toRefs(state),
    fetchMonitoringKeywords,
  }
}
