export type ServiceId = MonitoringsheetData.Services
export type Service = {
  id: ServiceId
  name: string
}

export const TwitterService: Service = {
  id: 'twitter',
  name: 'Twitter',
}
export const GoogleMapService: Service = {
  id: 'google_map',
  name: 'Google Map',
}
export const YoutubeChannelCommentService: Service = {
  id: 'youtube_channel_comment',
  name: 'Youtubeチャンネル',
}

export const services: Service[] = [
  TwitterService,
  GoogleMapService,
  YoutubeChannelCommentService,
]

export const findService = (id: ServiceId): Service => {
  switch (id) {
    case 'twitter':
      return TwitterService
    case 'google_map':
      return GoogleMapService
    case 'youtube_channel_comment':
      return YoutubeChannelCommentService
    default:
      throw new Error('service not found')
  }
}
