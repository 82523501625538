import {
  computed,
  InjectionKey,
  reactive,
  toRefs,
  useContext,
  watch,
} from '@nuxtjs/composition-api'

export default function userStore() {
  const { $api, $auth } = useContext()

  const state = reactive({
    name: null as string | null,
  })

  const getMe = async () => {
    const me = await $api.getMe()
    state.name = me.data.attributes.name
  }

  const clear = () => {
    state.name = null
  }

  const updateUser = async (user: { name: string | null }) => {
    await $api.updateUser(user)
    getMe()
  }

  const isNameUnSet = computed(() => $auth.user.value && !state.name)

  watch(
    $auth.user,
    (user) => {
      if (user) {
        if (!$auth.isLoginProcess.value) {
          getMe()
        }
      } else {
        clear()
      }
    },
    { immediate: true }
  )

  return {
    ...toRefs(state),
    updateUser,
    isNameUnSet,
  }
}

export type UserStore = ReturnType<typeof userStore>
export const UserStoreKey: InjectionKey<UserStore> = Symbol('UserStore')
