













import { defineComponent, provide } from '@nuxtjs/composition-api'
import alertStore, { AlertStoreKey } from '~/store/alert'
import appStore, { AppStoreKey } from '~/store/app'
import checksheetStore, { ChecksheetStoreKey } from '~/store/checksheet'
import checksheetRulesStore, {
  ChecksheetRulesStoreKey,
} from '~/store/checksheet-rules'
import labelsStore, { LabelsStoreKey } from '~/store/labels'
import monitoringsheetStore, {
  MonitoringsheetStoreKey,
} from '~/store/monitoringsheet'
import userStore, { UserStoreKey } from '~/store/user'
import reputationSentencesStore, {
  ReputationSentencesStoreKey,
} from '~/store/reputation-sentences'

export default defineComponent({
  middleware: ['loginCheck'],
  setup() {
    provide(AlertStoreKey, alertStore())
    provide(AppStoreKey, appStore())
    provide(UserStoreKey, userStore())

    provide(ChecksheetStoreKey, checksheetStore())
    provide(ChecksheetRulesStoreKey, checksheetRulesStore())
    provide(MonitoringsheetStoreKey, monitoringsheetStore())
    provide(ReputationSentencesStoreKey, reputationSentencesStore())
    provide(LabelsStoreKey, labelsStore())
  },
})
