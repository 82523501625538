













import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { PageLink, PageLinkGroup } from '~/store/app'

export default defineComponent({
  props: {
    links: {
      type: Array as PropType<(PageLink | PageLinkGroup)[]>,
      default: () => [],
    },
  },
})
