
































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'

export interface AppMenuItem {
  text?: string
  to?: string
  id?: string
  icon?: string
  divider?: boolean
  click?: Function
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<AppMenuItem[]>,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const opened = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    return {
      opened,
    }
  },
})
