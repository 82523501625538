import { Context, Plugin } from '@nuxt/types'
import { firebaseapp, AuthUser } from '@/plugins/firebaseapp'
import { ref } from '@nuxtjs/composition-api'

const LOGGEDIN_TIMEOUT = 5000

function authStore(context: Context) {
  const { $api } = context
  const user = ref<AuthUser | null>(null)
  const isLoginProcess = ref(false)

  const loginWithGoogle = async (): Promise<[UserData.User, Boolean]> => {
    isLoginProcess.value = true
    const provider = new firebaseapp.auth.GoogleAuthProvider()
    const result = await firebaseapp.auth().signInWithPopup(provider)
    try {
      const user = await $api.getMe()
      isLoginProcess.value = false
      return [user.data, false]
    } catch (e) {
      const user = await $api.registerUser(
        result.user?.displayName ?? undefined
      )
      isLoginProcess.value = false
      return [user.data, true]
    }
  }

  const logout = async () => {
    await firebaseapp.auth().signOut()
    user.value = null
  }

  const updateEmail = (email: string, password: string): Promise<void> => {
    const currentUser = firebaseapp.auth().currentUser
    if (currentUser?.email) {
      const credential = firebaseapp.auth.EmailAuthProvider.credential(
        currentUser.email,
        password
      )
      return currentUser.reauthenticateWithCredential(credential).then(() => {
        currentUser.updateEmail(email)
      })
    } else {
      throw new Error('Loggedin user not found')
    }
  }

  const sendEmailVerification = (): Promise<void> => {
    const currentUser = firebaseapp.auth().currentUser
    if (currentUser) {
      return currentUser.sendEmailVerification()
    } else {
      throw new Error('Loggedin user not found')
    }
  }

  const sendPasswordResetEmail = () => {
    const currentUser = firebaseapp.auth().currentUser
    if (currentUser?.email) {
      return firebaseapp.auth().sendPasswordResetEmail(currentUser.email)
    } else {
      throw new Error('Loggedin user not found')
    }
  }

  const waitAuth = () =>
    new Promise<void>((resolve, reject) => {
      if (isLoggedIn()) {
        resolve()
        return
      }
      setTimeout(reject, LOGGEDIN_TIMEOUT)
      firebaseapp.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          user.value = authUser
          resolve()
        } else {
          user.value = null
          reject(new Error('not login'))
        }
      })
    })

  const isLoggedIn = () => !!user.value

  return {
    waitAuth,
    loginWithGoogle,
    logout,
    sendEmailVerification,
    sendPasswordResetEmail,
    updateEmail,
    isLoggedIn,
    user,
    isLoginProcess,
  }
}

export type Auth = ReturnType<typeof authStore>

const plugin: Plugin = (context, inject) => {
  const store = authStore(context)
  inject('auth', store)
}

declare module '@nuxt/types' {
  interface Context {
    $auth: Auth
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  interface Store<S> {
    $auth: Auth
  }
}

export default plugin
