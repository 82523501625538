import { Middleware } from '@nuxt/types'

const middleware: Middleware = async ({ $auth: { waitAuth }, route }) => {
  try {
    await waitAuth()
  } catch {
    if (route.path !== '/login') {
      window.location.href = '/login'
    }
  }
}

export default middleware
