import {
  inject,
  InjectionKey,
  reactive,
  toRefs,
  unref,
} from '@vue/composition-api'
import _ from 'lodash'
import { Monitoringsheet, MonitoringsheetStoreKey } from './monitoringsheet'
import {
  ReputationSentence,
  ReputationSentenceServiceGroup,
  ReputationSentencesStoreKey,
} from './reputation-sentences'

export type MonitoringsheetReportStore = ReturnType<
  typeof monitoringsheetReportStore
>
export const MonitoringsheetReportStoreKey: InjectionKey<MonitoringsheetReportStore> = Symbol(
  'MonitoringsheetReportStore'
)

export interface ReportMonitoringsheet extends Monitoringsheet {}
export interface ReportReputationSentence extends ReputationSentence {
  index: number
}
export interface ReportReputationSentenceServiceGroup
  extends ReputationSentenceServiceGroup {
  positiveSentences: ReportReputationSentence[]
  negativeSentences: ReportReputationSentence[]
}

export type Sentiment = 'positive' | 'negative'

export default function monitoringsheetReportStore() {
  const { monitoringsheet, fetchMonitoringsheet } = inject(
    MonitoringsheetStoreKey
  )!
  const { groups, fetch: fetchReputationSentences } = inject(
    ReputationSentencesStoreKey
  )!

  const state = reactive({
    monitoringsheet: undefined as ReportMonitoringsheet | undefined,
    groups: [] as ReportReputationSentenceServiceGroup[],
  })

  const fetch = async (monitoringsheetId: string) => {
    if (
      !monitoringsheet.value ||
      monitoringsheet.value.id !== monitoringsheetId
    ) {
      await fetchMonitoringsheet(monitoringsheetId)
      await fetchReputationSentences(monitoringsheetId)
    }
    state.monitoringsheet = _.cloneDeep(unref(monitoringsheet))
    state.groups = _.cloneDeep(unref(groups)).map((group) => ({
      service: group.service,
      positiveSentences: group.positiveSentences.map((s, index) => ({
        index: index + 1,
        ...s,
      })),
      negativeSentences: group.negativeSentences.map((s, index) => ({
        index: index + 1,
        ...s,
      })),
    }))
  }

  return {
    ...toRefs(state),
    fetch,
  }
}
