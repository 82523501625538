import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'

type User = {
  name: string | null
  id: string
  role: string
}

export default function usersStore() {
  const { $api } = useContext()

  const state = reactive({
    users: [] as User[],
  })

  const fetchUsers = async () => {
    const users = await $api.getUsers()
    state.users = users.data.map((user) => {
      return {
        id: user.id,
        name: user.attributes.name,
        role: 'admin',
      }
    })
  }

  return {
    ...toRefs(state),
    fetchUsers,
  }
}

export type UsersStore = ReturnType<typeof usersStore>
export const UsersStoreKey: InjectionKey<UsersStore> = Symbol('UsersStore')
