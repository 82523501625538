import {
  InjectionKey,
  nextTick,
  reactive,
  toRefs,
} from '@nuxtjs/composition-api'

export default function alertStore() {
  const state = reactive({
    text: '' as string,
    open: false,
    color: 'success',
    timeout: 4000,
  })

  const setAlert = (
    st: Pick<typeof state, 'text'> & Partial<Omit<typeof state, 'open'>>
  ) => {
    state.open = false
    state.color = st.color ?? 'success'
    nextTick(() => {
      Object.assign(state, st)
      state.open = true
    })
  }

  return {
    setAlert,
    ...toRefs(state),
  }
}

export type AlertStore = ReturnType<typeof alertStore>
export const AlertStoreKey: InjectionKey<AlertStore> = Symbol('AlertStore')
