/* eslint-disable camelcase */
import {
  computed,
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { isFullFill } from '~/utils/array'

export type CheckStatus = {
  id: 'unchecked' | 'ok' | 'ng' | 'ignored'
  text: '未チェック' | 'OK' | 'NG' | '除外'
  color: 'unchecked' | 'ok' | 'ng' | 'ignored'
}

export const UncheckStatus: CheckStatus = {
  id: 'unchecked',
  text: '未チェック',
  color: 'unchecked',
}

export const OkStatus: CheckStatus = {
  id: 'ok',
  text: 'OK',
  color: 'ok',
}

export const NgStatus: CheckStatus = {
  id: 'ng',
  text: 'NG',
  color: 'ng',
}

export const IgnoredStatus: CheckStatus = {
  id: 'ignored',
  text: '除外',
  color: 'ignored',
}

export const selectableStatuses = [
  UncheckStatus,
  OkStatus,
  NgStatus,
  IgnoredStatus,
]

export interface ChecksheetRule {
  checksheetId: string
  ruleId: string | undefined
  id: string
  title: string
  checkStatus: CheckStatus
  advice: string
  criteria: string | undefined
  assetName: string
  assetReferenceUrl: string
  position: number
  opened: boolean
  memo: string
  label?: {
    id: string
    title: string
    color: string
    position: number
  }
}

export interface ChecksheetAsset {
  id: string
  name: string
  referenceUrl: string
  position: number
}

export interface ChecksheetAssetGroup {
  assetId: string | undefined
  checksheetId: string
  checksheetAsset: ChecksheetAsset
  checksheetRules: ChecksheetRule[]
  opened: boolean
}

export type ChecksheetRulesStore = ReturnType<typeof checksheetRulesStore>
export const ChecksheetRulesStoreKey: InjectionKey<ChecksheetRulesStore> = Symbol(
  'ChecksheetRulesStore'
)

export default function checksheetRulesStore() {
  const { $api } = useContext()

  const state = reactive({
    checksheetAssetGroups: [] as ChecksheetAssetGroup[],
  })

  const rules = computed(() =>
    state.checksheetAssetGroups.flatMap((rg) => rg.checksheetRules)
  )

  const convertCheckStatus = (
    status: ChecksheetRuleData.CheckStatus
  ): CheckStatus => {
    let st: CheckStatus
    switch (status) {
      case 'unchecked':
        st = UncheckStatus
        break
      case 'ok':
        st = OkStatus
        break
      case 'ng':
        st = NgStatus
        break
      case 'ignored':
        st = IgnoredStatus
    }
    return st
  }

  const isRule = (
    entity: JSONApi.Entity<unknown>
  ): entity is RuleData.Rule<'label'> => entity.type === 'rules'

  const isChecksheetRule = (
    entity: JSONApi.Entity<unknown>
  ): entity is ChecksheetRuleData.ChecksheetRule<'rule'> =>
    entity.type === 'checksheet_rules'

  const isLabel = (
    entity: JSONApi.Entity<unknown>
  ): entity is LabelData.Label => entity.type === 'labels'

  const fetch = async (
    checksheetId: string
  ): Promise<ChecksheetAssetGroup[]> => {
    const checksheetAssets = await $api.getChecksheetAssetsWithRules(
      checksheetId
    )
    return checksheetAssets.data.map((checksheetAsset) => {
      const checksheetRulesEntity = checksheetAsset.relationships.checksheet_rules.data
        .map((as) => checksheetAssets.included?.find((i) => i.id === as.id))
        .filter(isFullFill)
        .filter(isChecksheetRule)
      const sorted = checksheetRulesEntity.sort(
        (a, b) => a.attributes.position - b.attributes.position
      )
      const checksheetRules: ChecksheetRule[] = sorted.map((cr) => {
        const { title, advice, position, check_status, memo } = cr.attributes
        const {
          asset_name: assetName,
          asset_reference_url: assetReferenceUrl,
        } = checksheetAsset.attributes
        const checkStatus = convertCheckStatus(check_status)
        const rule = checksheetAssets.included
          ?.filter(isRule)
          .find((i) => i.id === cr.relationships.rule.data?.id)

        const criteria = rule?.attributes.criteria ?? undefined
        const labelRes = checksheetAssets.included
          ?.filter(isLabel)
          .find((i) => i.id === rule?.relationships.label.data?.id)
        const label = labelRes
          ? {
              id: labelRes.id,
              title: labelRes.attributes.title,
              color: labelRes.attributes.color,
              position: labelRes.attributes.position,
            }
          : undefined

        return {
          checksheetId,
          id: cr.id,
          ruleId: rule?.id,
          title,
          advice,
          assetName,
          assetReferenceUrl,
          checkStatus,
          criteria,
          position,
          memo,
          label,
          opened: false,
        }
      })

      const {
        asset_name,
        asset_reference_url,
        position,
      } = checksheetAsset.attributes

      return {
        assetId: checksheetAsset.relationships.asset.data?.id,
        checksheetId,
        checksheetAsset: {
          id: checksheetAsset.id,
          name: asset_name,
          referenceUrl: asset_reference_url,
          position,
        },
        checksheetRules,
        opened: true,
      }
    })
  }

  const setStateRetainOpened = (
    updateTarget: ChecksheetAssetGroup,
    prevRules: ChecksheetRule[],
    newRules: ChecksheetRule[]
  ) => {
    const mergedRules = newRules.map((nr) => {
      const prevRule = prevRules.find((pr) => pr.id === nr.id)
      nr.opened = prevRule?.opened ?? false
      return nr
    })
    updateTarget.checksheetRules = mergedRules
  }

  const fetchChecksheetAssetGroupRules = async (
    checksheetId: string,
    checksheetAssetId: string,
    clear = false
  ) => {
    const updateTarget = state.checksheetAssetGroups.find(
      (g) => g.checksheetAsset.id === checksheetAssetId
    )!!
    const prevRules = updateTarget.checksheetRules

    if (clear) {
      updateTarget.checksheetRules = []
    }
    const response = await fetch(checksheetId)
    const newRules = response.find(
      (g) => g.checksheetAsset.id === checksheetAssetId
    )!!.checksheetRules
    setStateRetainOpened(updateTarget, prevRules, newRules)
  }

  const fetchChecksheetRules = async (checksheetId: string) => {
    const checksheetGroups = await fetch(checksheetId)
    state.checksheetAssetGroups = checksheetGroups
  }

  return {
    ...toRefs(state),
    rules,
    fetchChecksheetRules,
    fetchChecksheetAssetGroupRules,
  }
}
