import { render, staticRenderFns } from "./DevNotify.vue?vue&type=template&id=17e3a71c&"
import script from "./DevNotify.vue?vue&type=script&lang=ts&"
export * from "./DevNotify.vue?vue&type=script&lang=ts&"
import style0 from "./DevNotify.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
