import {
  computed,
  InjectionKey,
  ref,
  useContext,
} from '@nuxtjs/composition-api'

export interface Rule {
  checklistId: string
  position: number
  id: string
  title: string
  criteria: string | null
  advice: string | null
  assetCategoryName?: string
  assetCategoryId?: string
  label?: {
    id: string
    title: string
    color: string
  }
  opened: boolean
}

export interface RuleGroup {
  checklistId: string
  categoryId: string
  categoryName: string
  rules: Rule[]
  opened: boolean
}

export default function rulesStore() {
  const { $api } = useContext()

  const groups = ref<RuleGroup[]>([])
  const rules = computed(() => groups.value.flatMap((group) => group.rules))

  const isAssetCategory = (
    arg: AssetCategoryData.AssetCategory | LabelData.Label
  ): arg is AssetCategoryData.AssetCategory => arg.type === 'asset_categories'

  const isLabel = (
    arg: AssetCategoryData.AssetCategory | LabelData.Label
  ): arg is LabelData.Label => arg.type === 'labels'

  const fetch = async (
    checklistId: string,
    categoryId?: string
  ): Promise<RuleGroup[]> => {
    const assetCategories = await $api.getAssetCategories()
    const rulesResponse = await $api.getChecklistRules(checklistId, categoryId)
    const includes = rulesResponse.included
    return assetCategories.data.map((ac) => {
      const categoryName = ac.attributes.name
      const categoryId = ac.id
      const groupRules = rulesResponse.data
        .filter((r) => r.relationships.asset_category.data?.id === categoryId)
        .map((r) => {
          const assetCategory = includes
            ?.filter(isAssetCategory)
            .find((i) => i.id === r.relationships.asset_category.data?.id)
          const labelRes = includes
            ?.filter(isLabel)
            .find((i) => i.id === r.relationships.label.data?.id)
          const label = labelRes
            ? {
                id: labelRes.id,
                title: labelRes.attributes.title,
                color: labelRes.attributes.color,
              }
            : undefined
          return {
            position: r.attributes.position,
            id: r.id,
            checklistId,
            title: r.attributes.title,
            criteria: r.attributes.criteria,
            advice: r.attributes.advice,
            assetCategoryId: assetCategory?.id,
            assetCategoryName: assetCategory?.attributes?.name,
            label,
            opened: false,
          }
        })
      return {
        categoryId,
        categoryName,
        checklistId,
        rules: groupRules,
        opened: true,
      }
    })
  }

  const setStateRetainOpened = (
    updateTarget: RuleGroup,
    prevRules: Rule[],
    newRules: Rule[]
  ) => {
    const mergedRules = newRules.map((nr) => {
      const prevRule = prevRules.find((pr) => pr.id === nr.id)
      nr.opened = prevRule?.opened ?? false
      return nr
    })
    updateTarget.rules = mergedRules
  }

  const fetchGroupRules = async (
    checklistId: string,
    categoryId: string,
    clear: boolean = false
  ) => {
    const updateTarget = groups.value.find((g) => g.categoryId === categoryId)!!
    const prevRules = updateTarget.rules
    if (clear) {
      updateTarget.rules = []
    }
    const response = await fetch(checklistId, categoryId)
    const newRules = response.find(
      (g) => g.categoryId === updateTarget.categoryId
    )!!.rules
    setStateRetainOpened(updateTarget, prevRules, newRules)
  }

  const fetchRules = async (checklistId: string) => {
    groups.value = await fetch(checklistId)
  }

  return {
    groups,
    rules,
    fetchRules,
    fetchGroupRules,
  }
}

export type RulesStore = ReturnType<typeof rulesStore>
export const RulesKey: InjectionKey<RulesStore> = Symbol('RulesStore')
