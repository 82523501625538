import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'

export interface Checklist {
  title: string
  description: string | null
  updatedAt: string
}

export default function checklistStore() {
  const { $api } = useContext()

  const state = reactive({
    checklist: null as Checklist | null,
  })

  const fetchChecklist = async (id: string) => {
    const response = await $api.getChecklist(id)
    // eslint-disable-next-line camelcase
    const { title, description, updated_at } = response.data.attributes
    const checklist: Checklist = {
      title,
      description,
      updatedAt: formatDateTimeString(updated_at),
    }
    state.checklist = checklist
  }

  return {
    ...toRefs(state),
    fetchChecklist,
  }
}

export type ChecklistStore = ReturnType<typeof checklistStore>
export const ChecklistKey: InjectionKey<ChecklistStore> = Symbol(
  'ChecklistStore'
)
