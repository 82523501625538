/* eslint-disable camelcase */
import {
  InjectionKey,
  reactive,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { formatDateTimeString } from '~/utils/datetime'
export interface Asset {
  organizationId: string
  id: string
  name: string
  position: number
  url: string
  assetCategoryId?: string
  assetCategoryName?: string
  createdAt: string
  updatedAt: string
}

export type AssetStore = ReturnType<typeof assetStore>
export const AssetStoreKey: InjectionKey<AssetStore> = Symbol('AssetStore')

export default function assetStore() {
  const { $api } = useContext()

  const state = reactive({
    assets: [] as Asset[],
  })

  const fetchAssets = async (organizationId: string) => {
    const response = await $api.getAssets(organizationId)
    const assets: Asset[] = response.data.map((a) => {
      const assetCategory = response.included?.find(
        (i) => i.id === a.relationships.asset_category?.data?.id
      )
      const {
        name,
        position,
        reference_url,
        created_at,
        updated_at,
      } = a.attributes
      return {
        organizationId,
        id: a.id,
        name,
        position,
        url: reference_url,
        assetCategoryId: assetCategory?.id,
        assetCategoryName: assetCategory?.attributes.name,
        createdAt: formatDateTimeString(created_at),
        updatedAt: formatDateTimeString(updated_at),
      }
    })
    state.assets = assets
  }

  return {
    ...toRefs(state),
    fetchAssets,
  }
}
