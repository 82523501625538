import {
  computed,
  inject,
  InjectionKey,
  reactive,
  toRefs,
  unref,
  useContext,
  watch,
} from '@nuxtjs/composition-api'
import _ from 'lodash'
import colors from 'vuetify/lib/util/colors'
import { Checksheet, ChecksheetStoreKey } from './checksheet'
import {
  ChecksheetAsset,
  ChecksheetAssetGroup,
  ChecksheetRule,
  ChecksheetRulesStoreKey,
} from './checksheet-rules'
import { Label, LabelsStoreKey } from './labels'
import { isFullFill } from '~/utils/array'

export type ChecksheetReportStore = ReturnType<typeof reportStore>
export const ChecksheetReportStoreKey: InjectionKey<ChecksheetReportStore> = Symbol(
  'ChecksheetReportStoreKey'
)

export interface ReportChecksheet extends Checksheet {
  masked: boolean
  stats: {
    ok: {
      count: number
      percent: number
    }
    ng: {
      count: number
      percent: number
    }
    total: {
      count: number
    }
    labels: {
      [labelId: string]: {
        ok: {
          count: number
        }
        all: {
          count: number
        }
      }
    }
  }
}
export interface ReportChecksheetRule extends ChecksheetRule {
  masked: boolean
}
export interface ReportChecksheetAsset extends ChecksheetAsset {}
export interface ReportChecksheetAssetGroup extends ChecksheetAssetGroup {
  checksheetAsset: ReportChecksheetAsset
  checksheetRules: ReportChecksheetRule[]
  stats: {
    ok: {
      count: number
      percent: number
    }
    ng: {
      count: number
    }
  }
}

export interface ReportComparedChecksheet {
  id: string
  name: string
  stats: {
    ok: {
      percent: number
    }
    ng: {
      count: number
    }
    total: {
      count: number
    }
    groups: {
      ok: {
        count: number
        percent: number
      }
      ng: {
        count: number
      }
    }[]
    labels: {
      [labelId: string]: {
        ok: {
          count: number
        }
        all: {
          count: number
        }
      }
    }
  }
}

interface ReportImprovedChecksheetAssetGroup {
  currentCheckseetAssetId: string | undefined
  rules: ReportChecksheetRule[]
}

interface CompareResult {
  name: string
  color: string
}

const onlyOkRulesAllCount = (groups: ReportChecksheetAssetGroup[]) =>
  groups.reduce((acc, group) => acc + onlyOkRules(group).length, 0)

const onlyNgRulesAllCount = (groups: ReportChecksheetAssetGroup[]) =>
  groups.reduce((acc, group) => acc + onlyNgRules(group).length, 0)

const onlyOkRules = (group: ReportChecksheetAssetGroup) =>
  group.checksheetRules.filter((rule) => rule.checkStatus.id === 'ok')

const onlyNgRules = (group: ReportChecksheetAssetGroup) =>
  group.checksheetRules.filter((rule) => rule.checkStatus.id === 'ng')

const onlyOk = (rule: ChecksheetRule) => rule.checkStatus.id === 'ok'
const onlyOkAndNg = (rule: ChecksheetRule) =>
  rule.checkStatus.id === 'ok' || rule.checkStatus.id === 'ng'

const round = (val: number) => Math.round(val)

export default function reportStore() {
  const { $api } = useContext()
  const { checksheet, fetchChecksheet } = inject(ChecksheetStoreKey)!
  const { checksheetAssetGroups, fetchChecksheetRules } = inject(
    ChecksheetRulesStoreKey
  )!
  const { labels, fetchLabels } = inject(LabelsStoreKey)!

  const state = reactive({
    checksheet: undefined as ReportChecksheet | undefined,
    filteredChecksheetAssetGroups: [] as ReportChecksheetAssetGroup[],
    useMask: false,
    maskStart: 3,
    useOkFilter: false,
    selectableMaskStarts: [1, 2, 3, 4, 5].map((i) => ({
      text: `${i}項目`,
      value: i,
    })) as SelectValue[],
    labels: [] as Label[],
    useComparing: false,
    selectableChecksheets: [] as SelectValue[],
    comparingChecksheetId: undefined as string | undefined,
    comparingChecksheet: undefined as ReportComparedChecksheet | undefined,
    improvedChecksheetAssetGroups: [] as ReportImprovedChecksheetAssetGroup[],
  })

  const previousResult = computed<CompareResult>(() => ({
    name: state.comparingChecksheet?.name ?? '前回',
    color: colors.red.lighten2,
  }))

  const currentResult = computed<CompareResult>(() => ({
    name: state.checksheet?.name ?? '今回',
    color: colors.blue.lighten2,
  }))

  const compareColors = computed(() => [
    previousResult.value.color,
    currentResult.value.color,
  ])

  const fetch = async (checksheetId: string) => {
    if (!checksheet.value || checksheet.value.id !== checksheetId) {
      await fetchChecksheet(checksheetId)
      await fetchChecksheetRules(checksheetId)
    }
    if (labels.value.length === 0) {
      await fetchLabels()
    }
    const groups = _.cloneDeep(
      unref(checksheetAssetGroups)
    ) as ReportChecksheetAssetGroup[]
    groups.forEach((group) => {
      group.checksheetRules = group.checksheetRules
        .filter((rule) => ['ok', 'ng'].includes(rule.checkStatus.id))
        .map((rule) => {
          rule.masked = false
          return rule
        })
    })
    state.filteredChecksheetAssetGroups = groups.map((group) => {
      const okCount = onlyOkRules(group).length
      const ngCount = onlyNgRules(group).length
      return {
        ...group,
        stats: {
          ok: {
            count: okCount,
            percent: round((okCount * 100) / (okCount + ngCount)),
          },
          ng: {
            count: onlyNgRules(group).length,
          },
        },
      }
    })
    state.checksheet = {
      ...(_.cloneDeep(unref(checksheet)) as Checksheet),
      masked: false,
      stats: {
        total: {
          count: groups.flatMap((group) => group.checksheetRules).length,
        },
        ok: {
          count: groups.flatMap(onlyOkRules).length,
          percent: round(
            (onlyOkRulesAllCount(groups) * 100) /
              (onlyOkRulesAllCount(groups) + onlyNgRulesAllCount(groups))
          ),
        },
        ng: {
          count: groups.flatMap(onlyNgRules).length,
          percent: round(
            (onlyNgRulesAllCount(groups) * 100) /
              (onlyOkRulesAllCount(groups) + onlyNgRulesAllCount(groups))
          ),
        },
        labels: (function () {
          const rules = groups.flatMap((group) => group.checksheetRules)
          const labels = _.uniqBy(
            rules.map((r) => r.label).filter(isFullFill),
            'id'
          )
          return Object.fromEntries(
            labels.map((label) => {
              const labelAllCount = rules
                .filter(onlyOkAndNg)
                .filter((r) => r.label?.id === label.id).length
              const labelOkCount = rules
                .filter(onlyOk)
                .filter((r) => r.label?.id === label.id).length
              return [
                label.id,
                {
                  all: {
                    count: labelAllCount,
                  },
                  ok: {
                    count: labelOkCount,
                  },
                },
              ]
            })
          )
        })(),
      },
    }
    state.labels = _.cloneDeep(unref(labels))
  }

  const fetchSelectableChecksheets = async () => {
    if (
      !state.checksheet ||
      !state.checksheet.organizationId ||
      !state.checksheet.checklistId
    ) {
      return
    }
    const checksheets = await $api.getChecksheetsFromChecklist(
      state.checksheet.organizationId,
      state.checksheet.checklistId
    )
    state.selectableChecksheets = checksheets.data
      .filter((checksheet) => checksheet.id !== state.checksheet!.id)
      .map((checksheet) => ({
        text: checksheet.attributes.name,
        value: checksheet.id,
      }))
  }

  watch(
    () => state.useComparing,
    (val) => {
      if (val) {
        fetchSelectableChecksheets()
      } else {
        state.comparingChecksheetId = undefined
        state.comparingChecksheet = undefined
        state.improvedChecksheetAssetGroups = []
      }
    }
  )

  const isChecksheetRule = (
    entity: JSONApi.Entity<unknown>
  ): entity is ChecksheetRuleData.ChecksheetRule<'rule'> =>
    entity.type === 'checksheet_rules'

  const isRule = (
    entity: JSONApi.Entity<unknown>
  ): entity is RuleData.Rule<'label'> => entity.type === 'rules'

  const isLabel = (
    entity: JSONApi.Entity<unknown>
  ): entity is LabelData.Label => entity.type === 'labels'

  const fetchComparingChecksheet = async (checksheetId: string) => {
    const response = await $api.getChecksheet(checksheetId)
    const checksheetAssets = await $api.getChecksheetAssetsWithRules(
      checksheetId
    )
    const checksheetRules =
      checksheetAssets.included?.filter(isChecksheetRule) ?? []
    const rules = checksheetAssets.included?.filter(isRule) ?? []
    const labels = checksheetAssets.included?.filter(isLabel) ?? []

    state.comparingChecksheet = {
      id: response.data.id,
      name: response.data.attributes.name,
      stats: {
        ok: {
          percent: round(
            (checksheetRules.filter((r) => r.attributes.check_status === 'ok')
              .length *
              100) /
              checksheetRules.filter((r) =>
                ['ok', 'ng'].includes(r.attributes.check_status)
              ).length
          ),
        },
        ng: {
          count: checksheetRules.filter(
            (r) => r.attributes.check_status === 'ng'
          ).length,
        },
        total: {
          count: checksheetRules.filter((r) =>
            ['ok', 'ng'].includes(r.attributes.check_status)
          ).length,
        },
        labels: (function () {
          const labelIds = labels.map((l) => l.id)
          return Object.fromEntries(
            labelIds.map((labelId) => {
              const labelAllCount = checksheetRules
                .filter((cr) =>
                  ['ok', 'ng'].includes(cr.attributes.check_status)
                )
                .map((checksheetRule) =>
                  rules.find(
                    (rule) =>
                      rule.id === checksheetRule.relationships.rule.data?.id
                  )
                )
                .filter(
                  (rule) => rule?.relationships.label.data?.id === labelId
                ).length
              const labelOkCount = checksheetRules
                .filter((cr) => cr.attributes.check_status === 'ok')
                .map((cr) =>
                  rules.find(
                    (rule) => rule.id === cr.relationships.rule.data?.id
                  )
                )
                .filter(
                  (rule) => rule?.relationships.label.data?.id === labelId
                ).length
              return [
                labelId,
                {
                  ok: {
                    count: labelOkCount,
                  },
                  all: {
                    count: labelAllCount,
                  },
                },
              ]
            })
          )
        })(),
        groups: checksheetAssets.data.map((asset) => {
          const assetRules = checksheetRules.filter((rule) =>
            asset.relationships.checksheet_rules.data
              .map((r) => r.id)
              .includes(rule.id)
          )
          const okCount = assetRules.filter(
            (r) => r.attributes.check_status === 'ok'
          ).length
          const ngCount = assetRules.filter(
            (r) => r.attributes.check_status === 'ng'
          ).length
          return {
            ok: {
              count: okCount,
              percent: round((okCount * 100) / (okCount + ngCount)),
            },
            ng: {
              count: ngCount,
            },
          }
        }),
      },
    }

    state.improvedChecksheetAssetGroups = checksheetAssets.data.map((asset) => {
      const previousNgRuleIds: string[] = checksheetRules
        .filter((rule) =>
          asset.relationships.checksheet_rules.data
            .map((r) => r.id)
            .includes(rule.id)
        )
        .filter((rule) => rule.attributes.check_status === 'ng')
        .map((rule) => rule.relationships.rule.data?.id)
        .filter(isFullFill)
      // 前回NGで、今回OKになっているものを抽出する
      const currentGroup = state.filteredChecksheetAssetGroups.find(
        (group) => group.assetId === asset.relationships.asset.data?.id
      )
      const improvedRules =
        currentGroup?.checksheetRules
          .filter((rule) => rule.checkStatus.id === 'ok')
          .filter(
            (rule) => rule.ruleId && previousNgRuleIds.includes(rule.ruleId)
          ) ?? []
      return {
        currentCheckseetAssetId: currentGroup?.checksheetAsset.id,
        rules: improvedRules,
      }
    })
  }

  watch(
    () => state.comparingChecksheetId,
    (val) => {
      if (val) {
        fetchComparingChecksheet(val)
      }
    }
  )

  const compareReady = computed(() => !!state.comparingChecksheet)

  return {
    ...toRefs(state),
    previousResult,
    currentResult,
    compareColors,
    compareReady,
    fetch,
  }
}
