// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dev_container_1YT-0{position:fixed;text-align:center;width:100%;top:0;background-color:transparent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dev_container": "dev_container_1YT-0"
};
module.exports = ___CSS_LOADER_EXPORT___;
